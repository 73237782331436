import * as React from 'react';
import { animated, useSprings } from 'react-spring';

export const ExpandToLeft = ({ children }: React.PropsWithChildren) => {
  const items = React.Children.toArray(children) as React.ReactNode[];
  const [props] = useSprings(items.length, (i) => ({
    config: { mass: 5, tension: 2000, friction: 200, duration: 100 },
    x: 0,
    from: { x: 10 * (i + 1) },
    delay: 5 * (i + 1),
  }));

  return (
    <>
      {props.map(({ x }, i) => (
        <animated.div key={i} style={{ x }}>
          {items[i]}
        </animated.div>
      ))}
    </>
  );
};
