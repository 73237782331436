export const getElementPosition = (el: HTMLElement) => {
  const position = {
    x: 0,
    y: 0,
  };

  // deal with all scroll shenanigans in the parent elements
  let node: HTMLElement = el;
  while (node) {
    if (node.tagName.toLowerCase() === 'body') {
      const scroll = {
        x: node.scrollLeft || document.documentElement?.scrollLeft || 0,
        y: node.scrollTop || document.documentElement?.scrollTop || 0,
      };
      position.x += node.offsetLeft - scroll.x + node.clientLeft;
      position.y += node.offsetTop - scroll.y + node.clientTop;
    } else {
      position.x += node.offsetLeft - node.scrollLeft + node.clientLeft;
      position.y += node.offsetTop - node.scrollTop + node.clientTop;
    }
    // offsetParent is (in this case) always an HTMLElement and not an Element
    node = node.offsetParent as HTMLElement;
  }

  return position;
};
