import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box } from '@mentimeter/ragnar-ui';
import {
  TitleSectionContainer,
  VotingMetaText,
  VotingTitle,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';
import { VideoPlayer } from './VideoPlayer';

export function Content() {
  const { useQuestion, usePresentation, useTranslate } = useVotingContext();
  const question = useQuestion();
  const { isAudiencePace } = usePresentation();
  const translate = useTranslate();

  return (
    <TitleSectionContainer alignItems="center">
      {isAudiencePace && question?.question_video_embed_url ? (
        <Box mb="space3" width="100%">
          <VideoPlayer videoUrl={question?.question_video_embed_url} />
        </Box>
      ) : (
        <QuestionImage
          src={question.image}
          translateFn={translate}
          mb="space3"
        />
      )}
      <VotingMetaText meta={question.title_meta} />
      <VotingTitle
        title={question.question}
        fontSize="100"
        lineHeight="relaxed"
      />
    </TitleSectionContainer>
  );
}
