import { useEffect } from 'react';
import _throttle from 'lodash/throttle';

const THROTTLE_BY = 16; // 60 fps

export const useWindowResize = (func: (...args: any[]) => void) => {
  useEffect(() => {
    const handleWindowResize = _throttle(func, THROTTLE_BY);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [func]);
};
