import type { PropsWithChildren } from 'react';
import { Text, type TextT } from '@mentimeter/ragnar-ui';

export const AnnouncedHeading = ({
  children,
  ...props
}: PropsWithChildren<TextT>) => {
  return (
    <Text as="h1" aria-live="polite" {...props}>
      {children}
    </Text>
  );
};
