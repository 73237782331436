/* eslint-disable menti-react/forbidden-filename */
import { removeEmojisFromString } from '@mentimeter/emojis';

// swastikas
const BAD_CHARS_REGEX = new RegExp(`[\u5350\u534D]`, 'g');
export const MAX_LENGTH = 200;

export const filterEmojisAndSpecialChars = (value: string) => {
  const cleanWord = value
    .trimStart()
    .replace(BAD_CHARS_REGEX, '')
    .slice(0, MAX_LENGTH);
  return removeEmojisFromString(cleanWord);
};
