import * as React from 'react';
import { useRagnar } from '@mentimeter/ragnar-react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { useTransition, animated } from 'react-spring';
import { Portal, PortalPositioner } from '../portal';
import { Backdrop } from '../backdrop';

export interface ActionsheetT {
  children: React.ReactNode;
  show: boolean;
  onDismiss?: () => void;
}

export const Actionsheet = ({
  show: shouldShow,
  onDismiss,
  children,
}: ActionsheetT) => {
  const { theme } = useRagnar();
  const { modal: zIndex } = theme.zIndex;
  const transition = useTransition(shouldShow, {
    from: { y: 100, opacity: 0, backdropOpacity: 0 },
    enter: { y: 0, opacity: 1, backdropOpacity: 1 },
    leave: { y: 100, opacity: 0, backdropOpacity: 0 },
  });

  return (
    <Portal>
      {transition(
        (styles, isShowing) =>
          isShowing && (
            <PortalPositioner
              zIndex={zIndex}
              className={clsx([
                'items-end',
                shouldShow ? 'pointer-events-auto' : 'pointer-events-none',
              ])}
            >
              <animated.div
                role="dialog"
                aria-modal="true"
                aria-live="polite"
                className={clsx([
                  'fixed',
                  'z-[2]',
                  'bottom-0',
                  'max-h-full',
                  'left-1/2',
                  'opacity-[var(--action-sheet-opacity)]',
                ])}
                style={
                  {
                    '--action-sheet-opacity': styles.opacity,
                    transform: styles.y.to(
                      (y) => `translate3d(-50%, ${y}%, 0)`,
                    ),
                  } as unknown as React.CSSProperties
                }
              >
                {children}
              </animated.div>
              <Backdrop
                as={animated.div}
                onClick={onDismiss}
                className="opacity-[var(--action-sheet-backdrop-opacity)]"
                style={
                  {
                    '--action-sheet-backdrop-opacity': styles.backdropOpacity,
                  } as React.CSSProperties
                }
              />
            </PortalPositioner>
          ),
      )}
    </Portal>
  );
};
