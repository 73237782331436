import type { QuizPlayerScore } from '@mentimeter/http-clients';
import type {
  ReceivingEvent,
  GameStateEnums,
  GenericGameState,
  Player,
  InitialGameStates,
} from './types';

type VoterReceivingEvents =
  | 'quiz_created'
  | 'quiz_started'
  | 'quiz_countdown_started'
  | 'quiz_ended'
  | 'presenter_marked_answer'
  | 'presenter_disconnected';
type PresenterReceivingEvents =
  | 'player_joined'
  | 'player_disconnected'
  | 'player_voted'
  | 'force_disconnected'
  | 'connect'
  | 'disconnect'
  | 'player_change_name';
type FollowerReceivingEvents =
  | 'game_state_updated'
  | 'presenter_marked_answer'
  | 'presenter_changed_player_name';

export type ReceivingEvents =
  | PresenterReceivingEvents
  | VoterReceivingEvents
  | FollowerReceivingEvents;

type PlayerJoinedEvent = ReceivingEvent<
  ReceivingEvents,
  'player_joined',
  Player
>;

type PlayerDisconnectedEvent = ReceivingEvent<
  ReceivingEvents,
  'player_disconnected',
  { identifier: Player['identifier'] }
>;
type PlayerVotedEvent = ReceivingEvent<
  ReceivingEvents,
  'player_voted',
  {
    identifier: Player['identifier'];
    questionPublicKey: string;
    voteKey: string;
  }
>;
type ForceDisconnectedEvent = ReceivingEvent<
  ReceivingEvents,
  'force_disconnected',
  unknown
>;

// Voting

type QuizCreatedEvent = ReceivingEvent<
  ReceivingEvents,
  'quiz_created',
  QuizCreatedGameState
>;

type QuizStartedEvent = ReceivingEvent<
  ReceivingEvents,
  'quiz_started',
  GetReadyGameState
>;

type QuizCountdownStartedEvent = ReceivingEvent<
  ReceivingEvents,
  'quiz_countdown_started',
  CountdownGameState
>;
type QuizEndedEvent = ReceivingEvent<
  ReceivingEvents,
  'quiz_ended',
  QuizEndedGameState
>;

type PresenterMarkedAnswerEvent = ReceivingEvent<
  ReceivingEvents,
  'presenter_marked_answer',
  ResultGameState
>;

type PresenterChangedPlayerNameEvent = ReceivingEvent<
  ReceivingEvents,
  'presenter_changed_player_name',
  undefined
>;

type PresenterDisconnectedEvent = ReceivingEvent<
  ReceivingEvents,
  'presenter_disconnected',
  unknown
>;

type GameStateUpdated = ReceivingEvent<
  ReceivingEvents,
  'game_state_updated',
  | QuizCreatedGameState
  | GetReadyGameState
  | CountdownGameState
  | QuizEndedGameState
>;

type PresenterReceivingMessages =
  | ForceDisconnectedEvent
  | PlayerVotedEvent
  | PlayerDisconnectedEvent
  | PlayerJoinedEvent;

type VoterReceivingMessages =
  | QuizCreatedEvent
  | QuizStartedEvent
  | QuizCountdownStartedEvent
  | QuizEndedEvent
  | PresenterMarkedAnswerEvent
  | PresenterDisconnectedEvent;

type FollowerReceivingMessages =
  | GameStateUpdated
  | PresenterMarkedAnswerEvent
  | PresenterChangedPlayerNameEvent;

export type ReceivingMessages =
  | PresenterReceivingMessages
  | VoterReceivingMessages
  | FollowerReceivingMessages;

interface PresenterCreateQuiz {
  gameState: GameStateEnums;
}

interface PresenterMarkedAnswer {
  scores: QuizPlayerScore[];
}

interface PresenterChangePlayerName {
  identifier: string;
  name: string;
}

interface PresenterEndQuiz {
  respondents: number;
  playerCount: number;
}

interface PresenterStartCountDown {
  playerCount: number;
  countdownToVote: number;
}
interface PresenterStartQuiz {
  playerCount: number;
  getReadyForCountdown: number;
}

type LobbyGameState = GenericGameState<
  GameStateEnums.LOBBY,
  { questionPublicKey: string }
>;
type QuizCreatedGameState = GenericGameState<
  InitialGameStates,
  { questionPublicKey: string }
>;
type QuizEndedGameState = GenericGameState<
  GameStateEnums.RESULT,
  { questionPublicKey: string }
>;
type GetReadyGameState = GenericGameState<
  GameStateEnums.GET_READY,
  { questionPublicKey: string; endAt: number; startAt: number }
>;
type CountdownGameState = GenericGameState<
  GameStateEnums.COUNTDOWN,
  { questionPublicKey: string; endAt: number; startAt: number }
>;

type ResultScoreGameState = GenericGameState<
  GameStateEnums.RESULT,
  { score: QuizPlayerScore['score'] }
>;

type ResultGameState = GenericGameState<
  GameStateEnums.RESULT,
  { questionPublicKey: string; score: QuizPlayerScore['score'] }
>;

type QuestionGameState = GenericGameState<
  GameStateEnums.QUESTION,
  { elapsedTime: number }
>;

type LeaderboardGameState = GenericGameState<
  GameStateEnums.LEADERBOARD,
  { score: QuizPlayerScore['score'] }
>;

type FinalLeaderboardGameState = GenericGameState<
  GameStateEnums.FINAL_LEADERBOARD,
  { score: QuizPlayerScore['score'] }
>;

type ErrorGameState = GenericGameState<
  GameStateEnums.ERROR,
  Record<string, never>
>;

export type GameStates =
  | LobbyGameState
  | GetReadyGameState
  | CountdownGameState
  | QuestionGameState
  | ResultScoreGameState
  | ResultGameState
  | LeaderboardGameState
  | FinalLeaderboardGameState
  | ErrorGameState;

interface EmitTuple<Payload, Response extends GameStates> {
  payload: Payload;
  response: Response;
}

export enum PresenterEvents {
  CreateQuiz = 'presenter_create_quiz',
  MarkedAnswer = 'presenter_marked_answer',
  EndQuiz = 'presenter_end_quiz',
  StartQuiz = 'presenter_start_quiz',
  StartCountdown = 'presenter_start_countdown',
  PlayerChangeName = 'player_change_name',
}

export enum FollowerEvents {
  Join = 'follower_join',
}

export enum VoterEvents {
  PlayerJoin = 'player_join',
  PlayerVote = 'player_vote',
  PlayerResults = 'player_get_result',
}

export interface EventMap {
  // Presenter
  [PresenterEvents.CreateQuiz]: EmitTuple<
    PresenterCreateQuiz,
    ResultGameState | LobbyGameState
  >;
  [PresenterEvents.MarkedAnswer]: EmitTuple<
    PresenterMarkedAnswer,
    ResultGameState
  >;
  [PresenterEvents.EndQuiz]: EmitTuple<PresenterEndQuiz, ResultGameState>;
  [PresenterEvents.StartQuiz]: EmitTuple<PresenterStartQuiz, GetReadyGameState>;
  [PresenterEvents.StartCountdown]: EmitTuple<
    PresenterStartCountDown,
    CountdownGameState
  >;
  [PresenterEvents.PlayerChangeName]: EmitTuple<
    PresenterChangePlayerName,
    ResultGameState
  >;
  // Voter
  [VoterEvents.PlayerJoin]: EmitTuple<{ player: Player }, GameStates>;
  [VoterEvents.PlayerVote]: EmitTuple<
    {
      clientTimestamps: { startAt: number; endAt: number };
      voteTimestamp: number;
      vote: [number | string];
    },
    QuestionGameState
  >;
  [VoterEvents.PlayerResults]: EmitTuple<void, ResultScoreGameState>;
  [FollowerEvents.Join]: EmitTuple<void, GameStates>;
}
