import runes from 'runes';
import { emojiRegex } from '@mentimeter/emojis';

const INTRA_WORD_SEPARATOR = '_';

export const getVoteText = (values: string[]): string => {
  const words = values
    .map((text) =>
      // Remove emojis and separate same-vote words with _
      text
        .replace(emojiRegex, '')
        .trim()
        .replace(/\s+/g, INTRA_WORD_SEPARATOR),
    )
    .join(' ')
    .trim();
  const emojis = values
    .map((_text) => {
      // Fix issues with unicode space characters
      const text = _text.replace(emojiRegex, ' $& ').trim();
      // Unicode-friendly character splitting for emojis, e.g., 'text 😊' --> ['t', 'e', 'x', 't', '😊']
      return runes(text)
        .filter((t) => emojiRegex.test(t))
        .join(' ');
    })
    .join(' ')
    .trim();

  // underscore_separated_words + space separated emojis (e.g., test_vote 😊 ✅ 🍻)
  return words.concat(
    words.length > 0 && emojis.length > 0 ? ` ${emojis}` : emojis,
  );
};
