import React from 'react';

const LEADERBOARD_RESULTS_DELAY = 6000;

export const useLeaderboardDelay = () => {
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, LEADERBOARD_RESULTS_DELAY);
    return () => clearInterval(timer);
  }, []);

  return ready;
};
