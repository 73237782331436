/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box } from '@mentimeter/ragnar-ui';
import React from 'react';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useQuestion } = useVotingContext();

  const question = useQuestion();

  return (
    <Box
      width="100%"
      alignItems="stretch"
      aria-atomic
      aria-live="polite"
      mb={3}
    >
      <TitleSectionWithImage image={question?.image} />
    </Box>
  );
}
