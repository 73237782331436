import type { ResponseCluster } from '@mentimeter/http-clients';
import React from 'react';
import type { GroupWithShape } from './types';

type GroupId = GroupWithShape['id'];
type OpenedGroups = Record<GroupId, boolean | undefined>;

export const useExpandableGroups = () => {
  const [openedGroups, setOpenedGroups] = React.useState<OpenedGroups>({});

  const handleGroupClick = (id: ResponseCluster['id']) => {
    setOpenedGroups((prev) => ({
      ...prev,
      [id]: !prev?.[id],
    }));
  };

  const getIsExpanded = (id: GroupId) => Boolean(openedGroups[id]);

  return {
    handleGroupClick,
    getIsExpanded,
  };
};
