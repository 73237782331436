import React from 'react';
import { animated, easings, useSprings } from 'react-spring';

const FADE_IN_DELAY_FACTOR = 25;
export interface TrailListProps<T extends { id: string }> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  flexDirection?: 'row' | 'column';
  animationDelay?: number;
  animationDuration?: number;
}

export const TrailList = <T extends { id: string }>({
  items,
  renderItem,
  flexDirection = 'column',
  animationDelay = 200,
  animationDuration = 550,
}: TrailListProps<T>) => {
  const [springs] = useSprings(
    items.length,
    (i) => ({
      from: { opacity: 0, y: -20, height: 0 },
      opacity: 1,
      height: 'auto',
      y: 0,
      delay: animationDelay + i * FADE_IN_DELAY_FACTOR,
      config: {
        duration: animationDuration,
        easing: easings.easeOutBack,
      },
    }),
    [],
  );

  return (
    <ul
      style={{
        listStyleType: 'none',
        flexDirection,
        display: 'flex',
        width: '100%',
      }}
    >
      {springs.map((styles, i) => {
        const item = items[i];
        if (!item) return null;
        return (
          <animated.li key={item.id} style={styles}>
            {renderItem(item)}
          </animated.li>
        );
      })}
    </ul>
  );
};
