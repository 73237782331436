const COMMON_REGEX =
  // basic latin & latin supplement (keeping accents)
  '!-&(-,\u002E\u002F\u003A-\u0040' +
  '\u005B-\u005E\u007B-\u00A7\u00A9\u00B3\u00B5\u00BF\u00D7\u00F7' +
  // general punctuation
  '\u2016-\u205E' +
  // arrows, mathematical operators, and other symbols
  '\u2100-\u2319' +
  '\u231C-\u25FF' +
  // swastikas
  '\u5350\u534D';

export const DISABLED_CHARS = new RegExp(`[${COMMON_REGEX}]`, 'g');
