import React from 'react';
import type { ResponseCluster } from '@mentimeter/http-clients';
import { Box, Clickable, Text } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { ActionCard, ActionCardText } from '@mentimeter/voting-ui';
import type { TranslationFn } from '@mentimeter/question-modules-types';
import { ExpandingAnimation } from './ExpandingAnimation';

interface SneakPeekLineProps {
  bg?: ColorsEnum;
  shouldShow: boolean;
}

const SneakPeekLine = ({ bg, shouldShow }: SneakPeekLineProps) => (
  <Box
    height="7px"
    width="calc(100% - 32px)"
    alignSelf="center"
    borderRadius="xl"
    bg={bg}
    extend={() => ({
      transition: 'opacity 1s ease',
      transitionDuration: shouldShow ? '1s' : '0.6s',
      opacity: shouldShow ? 1 : 0,
    })}
  />
);

interface GroupedResponsesCardProps {
  groupId: ResponseCluster['id'];
  label: string;
  tagLabel?: string | undefined;
  groupShape: React.ReactNode;
  numberOfVotes: number;
  isExpanded: boolean;
  onClick: (id: ResponseCluster['id']) => void;
  translate: TranslationFn;
}
export const GroupedResponsesCard = ({
  groupId,
  label,
  tagLabel,
  groupShape,
  numberOfVotes,
  isExpanded,
  onClick,
  children,
  translate,
}: React.PropsWithChildren<GroupedResponsesCardProps>) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const hasVotes = numberOfVotes > 0;

  const bgColor: ColorsEnum = (() => {
    if (isHovered) {
      return hasVotes ? 'secondaryWeakest' : 'buttonBgSecondaryHover';
    }
    return hasVotes ? 'secondaryWeak' : 'surfaceSunken';
  })();

  const upvotesAriaLabel =
    numberOfVotes === 1
      ? translate('open_ended.one_vote')
      : translate('open_ended.votes', { numberOfVotes });

  return (
    <Box key={groupId} mb="space5">
      <Clickable
        key={groupId}
        data-testid={`response-group-card-${groupId}`}
        bg={bgColor}
        py="space5"
        px="space4"
        // 1px + 7px of the SneakPeekLine make 8 in total
        mb={isExpanded ? '1px' : 'space1'}
        borderRadius="xl"
        width="100%"
        flexDirection="row"
        alignItems="center"
        minHeight="99px"
        aria-expanded={isExpanded}
        onClick={() => {
          onClick(groupId);
        }}
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
        zIndex={1} // so that focus ring is visible in front of the SneakPeekLine
        extend={() => ({
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
            },
          },
        })}
      >
        <Box width="40px" height="40px" mr="space2">
          {groupShape}
        </Box>
        <Box flexDirection="column" flex="1 1 auto">
          <Text
            color={hasVotes ? 'onSecondaryWeak' : 'text'}
            textAlign="left"
            maxWidth="80%"
            lineHeight="125"
          >
            {label}
          </Text>
          <ExpandingAnimation shouldExpand={numberOfVotes > 0}>
            <Text
              fontWeight="regular"
              fontFamily="heading"
              fontSize={4}
              lineHeight="relaxed"
              aria-label={upvotesAriaLabel}
            >
              {numberOfVotes}
            </Text>
          </ExpandingAnimation>
        </Box>
        {tagLabel && (
          <ActionCard mr="space2" alignSelf="flex-end">
            <ActionCardText>{tagLabel}</ActionCardText>
          </ActionCard>
        )}
        <Box
          mt={1}
          ml="auto"
          p="space1.5"
          extend={() => ({
            transition: 'transform 0.2s ease',
            transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0)',
          })}
        >
          <ChevronDownIcon color="text" size={2} />
        </Box>
      </Clickable>
      <SneakPeekLine bg={bgColor} shouldShow={!isExpanded} />
      {children}
    </Box>
  );
};
