import React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { OpenVotingResultsT } from '../types';
import { ResultsView } from './ResultsView';

interface ResultListProps {
  publicKey: string;
}
export const ResultsViewDataHandler = ({ publicKey }: ResultListProps) => {
  const { useResults } = useVotingContext();

  const { votes } = useResults<OpenVotingResultsT>(publicKey, true).data;
  const responses = votes ?? [];

  const resultsWithoutUpvotes = responses.filter((v) => !v.upvotes);

  const resultsWithUpvotes = [...responses]
    .filter((response) => response.upvotes)
    .sort((a, b) => b.upvotes - a.upvotes);

  const maxVoteCount = Math.max(...resultsWithUpvotes.map((v) => v.upvotes));

  return (
    <ResultsView
      resultsWithUpvotes={resultsWithUpvotes}
      resultsWithoutUpvotes={resultsWithoutUpvotes}
      maxVoteCount={maxVoteCount}
    />
  );
};
