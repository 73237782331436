import React, { useEffect, useState } from 'react';
import { Box, Button, Form } from '@mentimeter/ragnar-ui';
import { RefreshIcon } from '@mentimeter/ragnar-visuals';
import type { VotingSDKT } from '@mentimeter/question-modules-types';
import { usePrevious } from '@mentimeter/react-hooks';
import { SubmitVoteFormButton } from '../../../ui/Components/SubmitVoteFormButton';
import type { DropSectionsT, RankableChoice } from './types';
import { RankingContext } from './RankingContext';

interface RankingListWrapperT {
  rankableChoices: RankableChoice[];
  submitVote: ReturnType<VotingSDKT['useActions']>['vote'];
  onError: () => void;
  trackEvent: VotingSDKT['gaTrackEvent'];
  translate: ReturnType<VotingSDKT['useTranslate']>;
}

export const RankingListWrapper = ({
  rankableChoices,
  submitVote,
  onError,
  trackEvent,
  translate,
}: RankingListWrapperT) => {
  const [dropSections, setDropSections] = useState<DropSectionsT>({
    choices: rankableChoices,
    rankedChoices: [],
  });

  const prevRankableChoices = usePrevious(rankableChoices);

  useEffect(() => {
    // Reset ranking choices if number of choices changes
    if (
      prevRankableChoices &&
      prevRankableChoices.length !== rankableChoices.length
    ) {
      setDropSections({ choices: rankableChoices, rankedChoices: [] });
    }
  }, [prevRankableChoices, rankableChoices]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (dropSections?.rankedChoices?.length === 0) return onError();
    const rankedIds = dropSections.rankedChoices?.map((item) => item.id);
    submitVote(rankedIds, false);
  };

  const onReset = () => {
    setDropSections({ choices: rankableChoices, rankedChoices: [] });
    trackEvent('event status', 'Ranking removed items');
  };

  return (
    <Form onSubmit={handleSubmit} width="100%" height="100%" mt="space8">
      <RankingContext
        dropSections={dropSections}
        setDropSections={setDropSections}
        trackEvent={trackEvent}
        translate={translate}
      />
      <Box my="space2" width="100%" alignItems="center">
        <SubmitVoteFormButton />
        <Button
          size="large"
          variant="subtle"
          onClick={onReset}
          disabled={!dropSections.rankedChoices?.length}
          mt="space8"
          iconLeading={RefreshIcon}
        >
          {translate('buttons.reset')}
        </Button>
      </Box>
    </Form>
  );
};
