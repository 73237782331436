/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { useLayoutEffect } from 'react';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { FormView } from './components/FormView';
import { getStepLabel } from './getStepLabel';
import { UpvotingViewDataHandler } from './components/upvoting-view/UpvotingViewDataHandler';
import { GroupedResultsViewDataHandler } from './components/results-view/GroupedResultsViewDataHandler';
import { ResultsViewDataHandler } from './components/results-view/ResultsViewDataHandler';
import { useUpvotes } from './components/upvoting-view/useUpvotes';

export function Content() {
  const { useQuestion, usePresentationStore } = useVotingContext();
  const { step } = usePresentationStore();
  const {
    image,
    question: title,
    title_meta,
    question_description,
    voting_on_answers,
    question_styled,
  } = useQuestion();
  const stepState = getStepLabel(voting_on_answers, step);
  const showAllContent = stepState === 'submit-answers';

  return (
    <TitleSectionWithImage
      title={title}
      titleStyled={question_styled}
      meta={title_meta}
      description={showAllContent ? question_description : undefined}
      image={showAllContent ? image : undefined}
    />
  );
}
export function Interactive() {
  const { useQuestion, usePresentationStore } = useVotingContext();
  const { step, hasResetVoting, disableResetVoting, currentSlideState } =
    usePresentationStore();
  const {
    public_key,
    max_votes,
    voting_on_answers,
    multiple_votes_on_answers,
  } = useQuestion();

  // this is prop drilled 2 levels so that the upvotes are kept between steps
  // (in case the presenter moves back and forward, the voters upvotes will stay intact)
  const upvoting = useUpvotes();

  const stepState = getStepLabel(voting_on_answers, step);

  const groupsEnabled = Boolean(currentSlideState?.shouldShowGroups);

  useLayoutEffect(() => {
    // A11y: Focus on main when step is changed
    document.querySelector('main')?.focus();
  }, [step]);

  if (stepState === 'vote-on-answers') {
    return (
      <UpvotingViewDataHandler
        isGrouped={groupsEnabled}
        multipleUpvotesEnabled={multiple_votes_on_answers}
        publicKey={public_key}
        maxVotes={max_votes}
        upvoting={upvoting}
      />
    );
  }

  if (stepState === 'view-results') {
    if (groupsEnabled) {
      return <GroupedResultsViewDataHandler publicKey={public_key} />;
    } else {
      return <ResultsViewDataHandler publicKey={public_key} />;
    }
  }

  if (stepState === 'submit-answers') {
    return (
      <FormView
        hasVoting={voting_on_answers}
        hasResetVoting={hasResetVoting}
        disableResetVoting={disableResetVoting}
      />
    );
  }
  return null;
}
