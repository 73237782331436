import type { DropSectionsT, RankableChoice } from '../types';

const findDropSectionContainer = (
  dropSections: DropSectionsT,
  id: number,
): string => {
  if (id in dropSections) {
    return id.toString();
  }
  const container = Object.keys(dropSections).find((key) =>
    dropSections[key]?.find((item) => item.id === id),
  );
  return container ?? '';
};

export const getDropSectionContainers = (
  dropSections: DropSectionsT,
  activeId: number,
  overId: number,
) => {
  const activeContainer = findDropSectionContainer(dropSections, activeId);
  const overContainer = findDropSectionContainer(dropSections, overId);
  return {
    activeContainer,
    overContainer,
  };
};

export const getChoiceById = (choices: RankableChoice[], id: number) => {
  const choice = choices.find((item) => item.id === id);
  return choice ?? null;
};
