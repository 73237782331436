import {
  Box,
  CharacterCount,
  CharacterCountWrap,
  Label,
  SelectItem,
  TextInputItem,
  CheckItem,
} from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';

export type FieldTypes = 'text' | 'email' | 'checkbox' | 'date' | 'dropdown';

interface Props {
  id: number;
  label: string;
  type: FieldTypes;
  options?: Array<string>;
  required?: boolean;
  value?: Array<string>;
  onChange: (arg0: number, arg1: string | boolean, arg2?: string) => void;
}

const MAX_LENGTH = 140;

export const QuickFormField = ({
  id,
  label,
  type,
  value = [''],
  options = [],
  required,
  onChange,
}: Props) => {
  const {
    ThemeStyled: { StringParser },
    useTranslate,
  } = useVotingContext();
  const translate = useTranslate();

  // As the values always are encapsuled in an array (due to checkbox being multi-selectable),
  // we need to descruct the actual string value for the text inputs
  const [valueAsString] = value;

  switch (type) {
    case 'text':
      return (
        <Box width="100%">
          {/* @ts-expect-error-auto TS(2375) FIXME: Type '{ children: Element; htmlFor: string; requir... Remove this comment to see the full error message */}
          <Label htmlFor={`field-${id}`} required={required}>
            <StringParser source={label} disableLineBreaks />
          </Label>
          <CharacterCountWrap width="100%">
            <TextInputItem
              autoComplete="off"
              maxLength={MAX_LENGTH}
              type="text"
              required={required}
              name={`field-${id}`}
              aria-describedby={`field-${id}-character-counter`}
              id={`field-${id}`}
              onChange={(e) => onChange(id, e.target.value)}
              value={valueAsString}
            />
            <CharacterCount
              id={`field-${id}-character-counter`}
              maxLength={MAX_LENGTH}
              // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={valueAsString}
            />
          </CharacterCountWrap>
        </Box>
      );
    case 'email':
      return (
        <Box width="100%">
          {/* @ts-expect-error-auto TS(2375) FIXME: Type '{ children: Element; htmlFor: string; requir... Remove this comment to see the full error message */}
          <Label htmlFor={`field-${id}`} required={required}>
            <StringParser source={label} disableLineBreaks />
          </Label>
          <CharacterCountWrap width="100%">
            <TextInputItem
              autoComplete="off"
              maxLength={MAX_LENGTH}
              type="email"
              required={required}
              name={`field-${id}`}
              id={`field-${id}`}
              aria-describedby={`field-${id}-character-counter`}
              onChange={(e) => onChange(id, e.target.value)}
              value={valueAsString}
            />
            <CharacterCount
              id={`field-${id}-character-counter`}
              maxLength={MAX_LENGTH}
              // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={valueAsString}
            />
          </CharacterCountWrap>
        </Box>
      );
    case 'date':
      const isoToday = new Date().toISOString();
      const today = isoToday.substr(0, isoToday.indexOf('T'));
      return (
        <Box width="100%">
          {/* @ts-expect-error-auto TS(2375) FIXME: Type '{ children: Element; htmlFor: string; requir... Remove this comment to see the full error message */}
          <Label htmlFor={`field-${id}`} required={required}>
            <StringParser source={label} disableLineBreaks />
          </Label>
          <TextInputItem
            autoComplete="off"
            maxLength={MAX_LENGTH}
            type="date"
            required={required}
            name={`field-${id}`}
            id={`field-${id}`}
            onChange={(e) => onChange(id, e.target.value)}
            value={valueAsString || today}
          />
        </Box>
      );
    case 'dropdown':
      const dropdownOptions = !options ? [] : options;
      const initialValue = `--- ${translate('texts.choose_option')} ---`;
      const defaultOption = {
        key: `default-${id}`,
        label: initialValue,
        value: 'default',
      };
      const newOptions = dropdownOptions.map((o) => ({
        key: `${o}-${id}`,
        label: o,
        value: o,
      }));
      return (
        <Box width="100%">
          {/* @ts-expect-error-auto TS(2375) FIXME: Type '{ children: Element; htmlFor: string; requir... Remove this comment to see the full error message */}
          <Label htmlFor={`field-${id}`} required={required}>
            <StringParser source={label} disableLineBreaks />
          </Label>
          <SelectItem
            required={required}
            id={`field-${id}`}
            name={`field-${id}`}
            onChange={(e) => onChange(id, e.target.value)}
          >
            {[defaultOption, ...newOptions].map((o) => (
              <SelectItem.Option key={o.key} value={o.value}>
                {o.label}
              </SelectItem.Option>
            ))}
          </SelectItem>
        </Box>
      );
    case 'checkbox':
      const checkboxes = !options ? [] : options;
      return (
        <Box as="fieldset" width="100%">
          <Label
            as="legend"
            required={Boolean(required)}
            extend={() => ({
              '&:hover': {
                cursor: 'initial',
              },
            })}
          >
            {label}
          </Label>

          <Box width="100%" gap="space3">
            {checkboxes.map((o) => (
              <Box
                key={o}
                width="100%"
                flexDirection="row"
                alignItems="center"
                gap="space3"
              >
                <CheckItem
                  id={`field-${id}-${o}`}
                  name={`field-${id}-${o}`}
                  onChange={(e) => onChange(id, e.target.checked, o)}
                  checked={Array.isArray(value) ? value.includes(o) : false}
                />
                <Label htmlFor={`field-${id}-${o}`} mb="space0" flex="1 1 auto">
                  <StringParser source={o} disableLineBreaks />
                </Label>
              </Box>
            ))}
          </Box>
        </Box>
      );
    default:
      return 'Unknown type' as any;
  }
};
