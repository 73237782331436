import React, { useCallback } from 'react';

export type Upvotes = Record<string, number>;

export const useUpvotes = () => {
  const [upvotes, setUpvotes] = React.useState<Upvotes>({});

  const onUpvote = useCallback(
    (id: string, voteCount: number) => {
      setUpvotes((prev: Upvotes) => {
        return {
          ...prev,
          [id]: voteCount,
        };
      });
    },
    [setUpvotes],
  );

  const totalUpvotes = Object.values(upvotes).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return { upvotes, onUpvote, totalUpvotes };
};
