import React from 'react';
import { useSocketIoConnection } from './connection-provider';
import type { ReceivingMessages } from './events';

/**
 * Subscribe to messages sent from the Socket IO server.
 * 
 * Usage:
 * ```
  const useQuiz = () => {
    const listener: ReceivingMessages = {
      event: 'player_joined',
      callback: React.useCallback((p) => {}, []),
    };
    useSubscribe(listener);
  };
  ```
 */
export function useSubscribe(
  { event, callback }: ReceivingMessages,
  shouldSubscribe = true,
) {
  const { connection } = useSocketIoConnection();

  React.useEffect(() => {
    if (!shouldSubscribe) return;
    if (!connection?.socket) return;
    connection.socket?.on(event, callback);
    return () => void connection.socket?.off(event, callback);
  }, [event, callback, connection?.socket, shouldSubscribe]);
}
