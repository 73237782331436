import * as React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../box';

interface FloatT extends BoxT {
  animation: 'float' | 'spin' | 'fade' | 'bounce' | 'pulse';
  show?: boolean;
  reduceMotion?: boolean;
}

const ANIMATION_MAP: Record<FloatT['animation'], string> = {
  float: 'animate-float',
  spin: 'animate-spin',
  fade: 'animate-fade',
  bounce: 'animate-bounce',
  pulse: 'animate-pulse',
};

export const MotionContainer = ({
  children,
  className,
  animation,
  show,
  reduceMotion = false,
  ...rest
}: FloatT) => {
  return (
    <Box
      className={cn(
        !reduceMotion && ANIMATION_MAP[animation],
        'transition-opacity duration-300 ease-in-out motion-reduce:transition-none',
        `${animation === 'fade' && !show ? 'opacity-0' : 'opacity-100'}`,
        className,
      )}
      {...rest}
    >
      {children}
    </Box>
  );
};
