/* eslint-disable menti-react/filename-convention--jsx */
import { type FormEvent, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box, Form, ScreenReaderOnly } from '@mentimeter/ragnar-ui';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { PinableImage } from './PinableImage';

type PinVoteT = null | { x_percentage: number; y_percentage: number };

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();
  return (
    <TitleSectionWithImage
      meta={question.title_meta}
      title={question.question}
      description={question.question_description}
      titleStyled={question.question_styled}
    />
  );
}
export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote, skip: skipVote } = useActions();
  const translate = useTranslate();
  const question = useQuestion();

  const [vote, setVote] = useState<PinVoteT>(null);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!vote) {
      setShowModal(true);
      return;
    }

    submitVote(vote, false);
  };

  return (
    <Form onSubmit={handleSubmit} width="100%">
      <ScreenReaderOnly>
        {translate('pin_on_image.keyboard_instructions')}
      </ScreenReaderOnly>
      <Box width="100%" alignItems="stretch" mb={3}>
        {question.image && (
          <Box width="100%" mt={2}>
            <PinableImage
              imageUrl={question.image?.presets?.large?.url}
              onImagePin={(coordinates) =>
                setVote({
                  x_percentage: coordinates.x,
                  y_percentage: coordinates.y,
                })
              }
            />
          </Box>
        )}

        <SubmitVoteFormButton />

        <VotingConfirmationModal
          id="skip-vote-modal-pin-on-image"
          showModal={showModal}
          onConfirm={skipVote}
          onDismiss={() => setShowModal(false)}
          title={translate('messages.not_made_choice')}
          bodyText={translate('messages.continue_without_voting')}
          confirmButtonText={translate('buttons.ok')}
          dismissButtonText={translate('buttons.cancel')}
        />
      </Box>
    </Form>
  );
}
