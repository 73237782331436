import * as React from 'react';
import type { JSX } from 'react';

const padNumber = (number: number) => {
  return number < 10 ? `0${number}` : number;
};
export const toMMSS = (seconds: number) => {
  const min = Math.floor(seconds / 60);
  const sec = seconds - min * 60;
  return `${padNumber(min)}:${padNumber(sec)}`;
};

export const SecondsCountdown = ({
  timeRemaining,
  children,
}: {
  timeRemaining: number;
  children: (second: number) => JSX.Element;
}) => {
  const nextCounter = Math.floor(timeRemaining / 1000);
  const currentCounter = nextCounter + 1;
  const msDiff = timeRemaining - nextCounter * 1000;

  const [displayedNumber, setDisplayedNumber] = React.useState(currentCounter);

  React.useEffect(() => {
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Invalid_array_length
     */
    if (currentCounter < 0 || currentCounter >= Math.pow(2, 32)) {
      // Should set gamestate to question according to old logic
      return;
    }
    // If timeRemaining is 2.789, then currentCounter is 3. Generate array [0, 1, 2]
    // and then convert to ms and add the ms diff to get [789, 1789, 2789] which is the
    // list of timeouts in ms we want to set. Lastly, reverse it so that the index of the array
    // matches the number we want to set the counter to at that time.
    // End result: [2789, 1789, 0789].
    const times = Array.from(Array(currentCounter).keys())
      .map((index) => index * 1000 + msDiff)
      .reverse();

    // Create all the timers at once. When they run out they set displayedNumber to their index
    const timers = times.map((time, index) =>
      setTimeout(() => {
        setDisplayedNumber(index);
      }, time),
    );
    return () => timers.forEach(clearTimeout);
  }, [currentCounter, msDiff, nextCounter, timeRemaining]);

  return children(displayedNumber);
};
