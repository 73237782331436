import { useCallback, useEffect } from 'react';
import { Text } from '@mentimeter/ragnar-ui';
import { useAppSelector } from '../../redux-hooks';
import {
  Footer,
  NavigationButtonExperiment,
  PageLayout,
  ProgressBar,
} from '../../ui';
import { SlideInteractions } from '../questions/SlideInteractions';
import NoActive from '../questions/NoActive';
import { Module } from '../questions/Module';
import { FinishScreenExperiment } from '../finish-screen/FinishScreenExperiment';
import { SeriesLogo } from '../questions/SeriesLogo';
import { shouldSlideShowNavigation } from '../questions/shouldSlideShowNavigation';
import { useTranslate } from '../localization/Translate';
import { useOnHasFinishedVotingOnSlide } from '../../actions/useOnHasFinishedVotingOnSlide';
import { useOnSkipQuestion } from '../../actions/useOnSkipQuestion';
import { getQuestions } from '../../selectors';
import { usePresentationState } from '../../presentation-state';
import { ParticipateAgainNotification } from './ParticipateAgainNotification';
import { WaitForOpenModal } from './WaitForOpenModal';
import { useVoteAgain } from './useVoteAgain';
import { useAudiencePace } from './navigation/useAudiencePace';

export const AudiencePaceQuestion = ({
  voteKey,
  questionIndex,
}: {
  voteKey: string;
  questionIndex: number;
}) => {
  const translate = useTranslate();
  const { navigateToNextSlide } = useAudiencePace();
  const questions = useAppSelector(getQuestions);
  const numberOfQuestions = questions.length;
  const question = questions[questionIndex];
  const showEndScreen = questionIndex >= numberOfQuestions;
  const voteAgainEnabled = useAppSelector(
    (state) => state.series.vote_again_enabled,
  );
  const duplicatedVote = useAppSelector(
    (state) => state.voting.failure === 'duplicated',
  );

  const progress = questionIndex / numberOfQuestions;
  const isLastSlide = questionIndex === numberOfQuestions - 1;
  const voteAgain = useVoteAgain(voteKey);

  const navigateToNextSlideUnlessPartial = useCallback(
    (_questionId: string, partial: boolean) => {
      if (partial) return;
      navigateToNextSlide();
    },
    [navigateToNextSlide],
  );
  useOnHasFinishedVotingOnSlide(navigateToNextSlideUnlessPartial);
  useOnSkipQuestion(navigateToNextSlide);

  useEffect(() => {
    // Scroll to top whenever the questions changes
    // or we transition to one of the waiting states.
    window.scrollTo(0, 0);
  }, [question?.id]);

  const { participantIdentity } = usePresentationState();

  if (showEndScreen) return <FinishScreenExperiment />;
  if (!question) return <NoActive />;

  const isStaticSlide = ['slide', 'qfa'].includes(question.type);

  const shouldShowParticipationMessage =
    !isStaticSlide && participantIdentity.status === 'is_identified';

  return (
    <>
      <ProgressBar progress={progress} />
      <WaitForOpenModal question={question} />
      {duplicatedVote && voteAgainEnabled && (
        <ParticipateAgainNotification onClickStartOver={voteAgain} />
      )}

      <PageLayout footer={<Footer />}>
        <SeriesLogo />
        <Module question={question} key={question.id}>
          {(Content, Interactive) => (
            <>
              <Content />
              <Interactive />
            </>
          )}
        </Module>
        {shouldShowParticipationMessage && (
          <Text color="textWeaker" mt="space6">
            {translate('identified_responses.responding_as', {
              name: participantIdentity.name,
            })}
          </Text>
        )}
        {shouldSlideShowNavigation(question.type) && (
          <NavigationButtonExperiment
            onClick={navigateToNextSlide}
            isLastSlide={isLastSlide}
          />
        )}

        <SlideInteractions question={question} />
      </PageLayout>
    </>
  );
};
