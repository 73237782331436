import React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { Player } from '@mentimeter/quiz';
import { shortnameMap, createQuizSvgMapInDOM } from '@mentimeter/quiz-emojis';
import { ScaleIn } from '../ui/Transitions';

export const AvatarStatusBubble = ({
  children,
  bg = 'neutralWeak',
}: React.PropsWithChildren<{ bg?: string }>) => {
  return (
    <Box
      mt="space1"
      bg={bg}
      borderRadius="full"
      width="40px"
      height="40px"
      alignItems="center"
      justifyContent="center"
      borderWidth={3}
      borderStyle="solid"
      borderColor="bg"
    >
      {children}
    </Box>
  );
};

export const Avatar = ({
  name,
  emojiShortName,
  color,
}: {
  name: string;
  emojiShortName: string;
  color: string;
}) => {
  React.useEffect(() => {
    createQuizSvgMapInDOM();
  }, []);

  return (
    <Box
      width="180px"
      height="180px"
      bg={color}
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      aria-hidden
    >
      <svg data-testid={`avatar-for-${emojiShortName}`}>
        <use href={`#emoji-${shortnameMap[emojiShortName]}`} />
      </svg>
    </Box>
  );
};

export const AvatarWrapper = ({
  player,
  playerColor,
}: {
  player: Player;
  playerColor: string;
}) => {
  return (
    <ScaleIn>
      <Box alignItems="center" my="space4">
        <Avatar
          color={playerColor}
          name={player.name || player.emojiName}
          emojiShortName={player.emojiShortname}
        />
      </Box>
    </ScaleIn>
  );
};
