/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  TitleSectionContainer,
  VotingMetaText,
  VotingTitle,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const question = useQuestion();
  const translate = useTranslate();

  return (
    <TitleSectionContainer alignItems="center">
      <QuestionImage src={question.image} mb="space3" translateFn={translate} />
      <VotingMetaText meta={question.title_meta} />
      <VotingTitle
        title={question.question}
        fontSize="100"
        lineHeight="relaxed"
      />
    </TitleSectionContainer>
  );
}
