import React, { useCallback, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import { MinusIcon, PlusIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import {
  NUMBER_PICKER_TAP_AREA,
  NumberPickerButton,
} from './NumberPickerButton';
import { ActionCardText } from './ActionCardText';
import { ExpandToLeft } from './motion/ExpandToLeft';

export interface NumberPickerProps {
  id: string;
  value: number;
  disabled?: boolean;
  onChange: (newValue: number | undefined) => void;
  addLabel: string;
  subtractLabel: string;
  min?: number;
  max?: number;
  step?: number;
}

export function NumberPicker({
  id,
  value: initialValue,
  onChange,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  disabled = false,
  addLabel,
  subtractLabel,
}: NumberPickerProps) {
  const value = useMemo(() => {
    /**
     * Ensure ranges are respected by edge cases,
     * such as the Storybook controls.
     */
    if (initialValue < min) {
      return min;
    } else if (initialValue > max) {
      return max;
    }

    return initialValue;
  }, [initialValue, max, min]);

  const onAdd = useCallback(() => {
    const total = value + step;
    const newValue = total > max ? max : total;
    onChange(newValue);
  }, [value, step, max, onChange]);

  const onSubtract = useCallback(() => {
    const total = value - step;
    const newValue = total < min ? min : total;
    onChange(newValue);
  }, [value, step, min, onChange]);

  const buttonWidth = 32;
  const enterAnimation = useSpring({
    from: { width: buttonWidth },
    to: { width: value === 0 ? buttonWidth : 90 },
    exit: { width: buttonWidth },
    config: { mass: 5, tension: 2000, friction: 200, duration: 100 },
  });

  const hasValue = value > 0;

  return (
    <animated.div style={enterAnimation}>
      <Box
        bg={disabled ? 'disabledStrong' : hasValue ? 'secondary' : 'bg'}
        position="relative"
        borderRadius="full"
        borderStyle="solid"
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        extend={() => ({
          // transition to primary color instantly as it's a background color for other elements as well
          transition: `${hasValue ? '0s' : '0.2s'} ease`,
        })}
      >
        <NumberPickerButton
          data-testid={`number-add-${id}`}
          aria-controls={`action-text-${id}`}
          aria-label={addLabel}
          onClick={onAdd}
          disabled={disabled}
          hasValue={hasValue}
          tapArea={{ right: `${NUMBER_PICKER_TAP_AREA}px` }}
        >
          <PlusIcon />
        </NumberPickerButton>
        <ExpandToLeft>
          {hasValue && (
            <ActionCardText
              id={id}
              disabled={disabled}
              color={
                disabled
                  ? 'onDisabledStrong'
                  : hasValue
                    ? 'onSecondary'
                    : 'text'
              }
              fontSize="100"
              px="space0"
              py="space0"
            >
              {value}
            </ActionCardText>
          )}
          {hasValue && (
            <NumberPickerButton
              data-testid={`number-subtract-${id}`}
              aria-controls={`action-text-${id}`}
              aria-label={subtractLabel}
              onClick={onSubtract}
              disabled={disabled}
              hasValue={hasValue}
              tapArea={{ left: `${NUMBER_PICKER_TAP_AREA}px` }}
            >
              <MinusIcon />
            </NumberPickerButton>
          )}
        </ExpandToLeft>
      </Box>
    </animated.div>
  );
}
