export enum GameStateEnums {
  LOBBY = 'lobby',
  COUNTDOWN = 'countdown',
  GET_READY = 'get_ready',
  QUESTION = 'question',
  RESULT = 'result',
  AWAIT_MARKS = 'await_marks',
  ERROR = 'error',
  LEADERBOARD = 'leaderboard',
  FINAL_LEADERBOARD = 'final_leaderboard',
}

export type InitialGameStates =
  | GameStateEnums.FINAL_LEADERBOARD
  | GameStateEnums.LEADERBOARD
  | GameStateEnums.RESULT
  | GameStateEnums.LOBBY;

export interface SyncedControlState {
  canStartQuiz: boolean;
  canShowResults: boolean;
  showResults: boolean;
  quizStarted: number;
}

export interface UpdatePlayerBody {
  name: string;
}

export interface Timestamps {
  startAt: number;
  endAt: number;
}

export interface Player {
  emojiName: string;
  emojiShortname: string;
  identifier: string;
  index: number;
  name: null | string;
}

export interface Score {
  total: {
    totalScore: number;
    position: number;
  };
  question?: {
    score: number;
    correctAnswer: boolean;
    choiceId: number;
  };
}

export type ResultsResponse = Array<{ player: Player; score: Score }>;

export enum ClientErrorCodes {
  CREATE_QUIZ_ERROR = 'creating the quiz',
  START_QUIZ_ERROR = 'starting the quiz',
  FOLLOWER_JOIN_QUIZ_ERROR = 'joining quiz',
  END_QUIZ_ERROR = 'ending the quiz',
  CHANGE_PLAYER_NAME_ERROR = "changing the player's name",
  QUESTION_SCORE_ERROR = 'loading the results',
  TOO_MANY_PLAYERS = 'too_many_players',
  FAILED_TO_CREATE_OR_UPDATE_PLAYER = 'failed_to_create_or_update_player',
  VOTE_FAILURE = 'vote_failure',
  MULTIPLE_DEVICES = 'multiple_devices',
  RESULTS_FETCH = 'results_fetch',
  NO_CONNECTION = 'no_connection',
}

export enum ErrorCodes {
  NO_AVAILABLE_QUIZ = 'no_available_quiz',
  CREATE_QUIZ_ERROR = 'create_quiz_error',
  FAILED_TO_POST_VOTE = 'failed_to_post_vote',
  PLAYER_VOTED_TOO_SLOW = 'player_voted_too_slow',
  PLAYER_VOTED_TOO_FAST = 'player_voted_too_fast',
  PLAYER_HAS_ALREADY_VOTED = 'player_has_already_voted',
  MISSING_PARAMETER = 'missing_parameter',
  FAILED_TO_CREATE_OR_UPDATE_PLAYER = 'failed_to_create_or_update_player',
  FAILED_TO_GET_RESULT = 'failed_to_get_result',
  FAILED_TO_GET_RESULT_GAME_STATE = 'failed_to_get_result_game_state',
}

export interface ReceivingEvent<
  AllEvents,
  EventName extends AllEvents,
  Payload,
> {
  event: EventName;
  callback: (payload: Payload) => void;
}

export interface GenericGameState<
  State extends GameStateEnums,
  GameStateData extends Record<string, unknown>,
> {
  errorCode: ErrorCodes | ClientErrorCodes;
  gameState: State;
  gameStateData: GameStateData;
}

export interface VoterConfig {
  voteKey: string;
  questionPublicKey: string;
  identifier: string;
}

export interface PresenterConfig {
  voteKey: string;
  questionPublicKey: string;
  questionAdminKey: string;
}

export type Config = VoterConfig | PresenterConfig;
