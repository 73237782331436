import React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  Text,
  Box,
  TextareaItem,
  CharacterCountWrap,
  CharacterCount,
} from '@mentimeter/ragnar-ui';
interface PropsT {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength: number;
  multipleVotes: boolean;
  voteAgain: boolean;
  submitFeedback: boolean;
  emojiFilterWarning: boolean;
}

export function Option({
  value,
  onChange,
  maxLength,
  multipleVotes,
  voteAgain,
  submitFeedback,
  emojiFilterWarning,
}: PropsT) {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const describedByIds = [
    'question-description',
    'question-description-character-counter',
    multipleVotes && 'can-multiple-answers',
    voteAgain && submitFeedback && 'thanks-input-add-another',
    emojiFilterWarning && 'filter-emoji',
  ]
    .filter((id) => id)
    .join(' ');

  return (
    <Box width="100%" alignItems="stretch">
      <CharacterCountWrap multiline>
        <TextareaItem
          autoComplete="off"
          aria-labelledby="question-title"
          aria-describedby={describedByIds || undefined}
          name="open-ended-input"
          maxLength={maxLength}
          minHeight="138px"
          placeholder={translate('open_ended.short_answers', {
            maxLength,
          })}
          value={value}
          onChange={(e) => onChange(e)}
        />
        <CharacterCount
          id="question-description-character-counter"
          maxLength={maxLength}
          value={value}
          multiline
        />
      </CharacterCountWrap>

      <Box width="100%" gap="space2" mt="space4">
        {multipleVotes && (
          <Text
            id="can-multiple-answers"
            as="p"
            color="textWeaker"
            lineHeight="relaxed"
          >
            {translate('messages.can_multiple_answers')}
          </Text>
        )}
        {voteAgain && submitFeedback && (
          <Text
            id="thanks-input-add-another"
            as="p"
            color="textWeaker"
            lineHeight="relaxed"
          >
            {translate('word_cloud.thanks_input_add_another')}
          </Text>
        )}
        {emojiFilterWarning && (
          <Text as="p" color="textWeaker" lineHeight="relaxed">
            {translate('messages.fields_emoji')}
          </Text>
        )}
      </Box>
    </Box>
  );
}
