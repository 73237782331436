import type { ComponentType } from 'react';
import {
  type ModuleId,
  VALID_MODULE_IDS,
  type ValidModuleId,
} from '@mentimeter/http-clients';
import * as hundredPoints from './100-points';
import * as big from './big';
import * as bullets from './bullets';
import * as choices from './choices';
import * as deprecated from './deprecated';
import * as document from './document';
import * as freeText from './free-text';
import * as googleSlides from './google-slides';
import * as heading from './heading';
import * as image from './image';
import * as instructions from './instructions';
import * as metadata from './metadata';
import * as miro from './miro';
import * as number from './number';
import * as numericalQuestion from './numerical-question';
import * as open from './open';
import * as paragraph from './paragraph';
import * as pinOnImage from './pin-on-image';
import * as powerpoint from './powerpoint';
import * as ranking from './ranking';
import * as qfa from './qfa';
import * as quizChoices from './quiz-choices';
import * as quizLeaderboard from './quiz-leaderboard';
import * as quizOpen from './quiz-open';
import * as quote from './quote';
import * as rating from './rating';
import * as scales from './scales';
import * as video from './video';
import * as wordcloud from './wordcloud';

const MODULE_IMPORT_MAP: Record<
  ValidModuleId,
  {
    Interactive?: ComponentType;
    Content: ComponentType;
  }
> = {
  '100-points': hundredPoints,
  big,
  bullets,
  choices,
  deprecated,
  document,
  'free-text': freeText,
  'google-slides': googleSlides,
  heading,
  image,
  instructions,
  metadata,
  miro,
  number,
  'numerical-question': numericalQuestion,
  open,
  paragraph,
  'pin-on-image': pinOnImage,
  powerpoint,
  ranking,
  qfa,
  'quiz-choices': quizChoices,
  'quiz-leaderboard': quizLeaderboard,
  'quiz-open': quizOpen,
  quote,
  rating,
  scales,
  video,
  wordcloud,
};

const EmptyComponent = () => null;

export const getValidModuleIdFromString = (moduleId: string): ValidModuleId => {
  const validId = VALID_MODULE_IDS.find((id) => id === moduleId);

  return validId ?? 'deprecated';
};

export const getModuleById = (moduleId: ModuleId) => {
  const validModuleId = getValidModuleIdFromString(moduleId);

  const { Interactive, Content } = MODULE_IMPORT_MAP[validModuleId];

  const InteractiveWithFallback = Interactive ?? EmptyComponent;
  return { Interactive: InteractiveWithFallback, Content };
};
