import React from 'react';
import type { TranslationFn } from '@mentimeter/question-modules-types';
import { TrailList } from '@mentimeter/voting-ui';
import { useExpandableGroups } from '../useExpandableGroups';
import { ResponsesGroup } from '../ResponsesGroup';
import type { GroupWithShape } from '../types';
import type { Upvotes } from './useUpvotes';
import { ResponseWithUpvoting } from './ResponseWithUpvoting';

export interface GroupedResponsesListProps {
  groups: GroupWithShape[];
  onUpvote: (id: string, voteCount: number) => void;
  upvotes: Upvotes;
  hasMultipleUpvotes: boolean;
  translate: TranslationFn;
}

export const GroupedResponsesList = ({
  groups,
  onUpvote,
  upvotes,
  hasMultipleUpvotes,
  translate,
}: GroupedResponsesListProps) => {
  const { handleGroupClick, getIsExpanded } = useExpandableGroups();

  return (
    <TrailList
      items={groups}
      renderItem={(group) => {
        const groupTotalUpvotes = group.responses
          .map((response) => upvotes[response.id] ?? 0)
          .reduce((acc, curr) => acc + curr, 0);

        return (
          <ResponsesGroup
            key={group.id}
            group={group}
            tagLabel={undefined}
            groupShape={group.shape}
            isExpanded={getIsExpanded(group.id)}
            totalUpvotes={groupTotalUpvotes}
            handleGroupClick={handleGroupClick}
            // eslint-disable-next-line react/no-unstable-nested-components -- Do not define components during render.
            responseRenderer={(response) => (
              <ResponseWithUpvoting
                item={{
                  answer: response.text,
                  id: response.id,
                }}
                onUpvote={onUpvote}
                nrOfUpvotes={upvotes[response.id] ?? 0}
                canHaveMultipleUpvotes={hasMultipleUpvotes}
                mb="space2"
              />
            )}
            translate={translate}
          />
        );
      }}
    />
  );
};
