/* eslint-disable menti-react/filename-convention--jsx */
import { useCallback, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Text, Box, Form } from '@mentimeter/ragnar-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { SliderInput } from './SliderInput';

export function displayValueToStep({
  displayValue,
  min,
  scale,
}: {
  displayValue: number;
  min: number;
  scale: number;
}) {
  return (displayValue - min) / scale;
}

export function stepToDisplayValue({
  step,
  min,
  scale,
}: {
  step: number;
  min: number;
  scale: number;
}) {
  return step * scale + min;
}

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();
  return (
    <TitleSectionWithImage
      image={question.image}
      meta={question.title_meta}
      title={question.question}
      description={question.question_description}
      titleStyled={question.question_styled}
    />
  );
}

export function Interactive() {
  const { useQuestion, useActions } = useVotingContext();

  const question = useQuestion();
  const { error, vote: submitVote } = useActions();

  const range = question.range;
  const scale = question.module_custom_data?.scale || 1;
  const min = question.module_custom_data?.display_min;
  const max = stepToDisplayValue({ min, scale, step: range.max || 10 });
  const [vote, setVote] = useState<number>(min);
  const choice = question.choices[0];

  function isValidVote(voteValue: number) {
    return voteValue >= min && voteValue <= max;
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const rawValue = e.target.value;
    if (rawValue.length > 0) {
      const voteValue = parseInt(rawValue, 10);
      if (isValidVote(voteValue)) {
        setVote(voteValue);
      }
    }
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!choice) return;

      const votePayload = {
        [choice.id]: [
          displayValueToStep({ displayValue: vote, min, scale }),
          min,
        ],
      };

      submitVote(votePayload, false);
    },
    [choice, vote, min, scale, submitVote],
  );

  const percentage = Math.min(Math.max((vote - min) / (max - min), 0), 1) || 0;

  if (!choice) return null;

  return (
    <Form onSubmit={handleSubmit} width="100%">
      <Box width="100%" py="space6">
        <Box
          width="100%"
          flexDirection="row"
          alignItems="center"
          gap="space1"
          pb="space6"
        >
          <Text as="h2" fontSize="100" fontWeight="regular" color="text">
            Your answer:
          </Text>
          <Text fontSize="112.5" fontWeight="semiBold" color="text">
            {vote}
          </Text>
        </Box>
        <SliderInput
          choiceId={choice.id}
          label={question.question}
          min={min}
          max={max}
          scale={scale}
          percentage={percentage}
          error={error}
          value={vote}
          onChange={onChange}
        />
      </Box>

      <SubmitVoteFormButton />
    </Form>
  );
}
