import React, { useEffect } from 'react';
import { useSocketIoConnection } from './connection-provider';
import type {
  EventMap,
  FollowerEvents,
  PresenterEvents,
  VoterEvents,
} from './events';
import type { Config } from './types';

const EMIT_TIMEOUT_IN_MS = 10000;

/**
 * Emit messages to the Quiz API
 *
 * @param config - The configuration of the quiz. N.B. needs to be a stable reference
 */
export function useEmit(config: Config) {
  const { connection } = useSocketIoConnection();
  const latencyFnRef = React.useRef(connection?.timeSync.getLatency);
  const abortControllerRef = React.useRef<Record<string, AbortController>>({});

  useEffect(() => {
    const controller = new AbortController();
    abortControllerRef.current[config.questionPublicKey] = controller;
    return () => {
      controller.abort();
    };
  }, [config]);

  useEffect(() => {
    latencyFnRef.current = connection?.timeSync.getLatency;
  }, [connection?.timeSync]);

  const emit = React.useCallback(
    async <Event extends PresenterEvents | VoterEvents | FollowerEvents>(
      event: Event,
      data: EventMap[Event]['payload'],
    ): Promise<EventMap[Event]['response']> => {
      if (!connection?.socket) throw new Error('No socket');
      if (abortControllerRef.current[config.questionPublicKey]?.signal.aborted)
        throw new Error('AbortError');

      const payload = {
        ...config,
        ...data,
        latency: `${latencyFnRef.current ? latencyFnRef.current() : 0}ms`,
        ioLatency: '-1ms',
      };

      const response = await connection?.socket
        ?.timeout(EMIT_TIMEOUT_IN_MS)
        .emitWithAck(event, payload);
      return response;
    },
    [connection?.socket, config],
  );

  return { emit };
}
