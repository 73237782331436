import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { ResponseCluster } from '@mentimeter/http-clients';
import { getGroupsShapes } from '@mentimeter/slides-presentation-ui/groups/group-shapes';
import { UNGROUPED_CLUSTER_ID } from '../../ungroupedClusterId';
import type { GroupWithShape } from '../types';
import { GroupedResultsView } from './GroupedResultsView';

interface ResultListProps {
  publicKey: string;
}
export const GroupedResultsViewDataHandler = ({
  publicKey,
}: ResultListProps) => {
  const { useGroupedResults, useTheme, useTranslate } = useVotingContext();
  const { fillColors } = useTheme();
  const translate = useTranslate();

  const groupedResults = useGroupedResults(publicKey, true).data;

  const groupedResponses = groupedResults?.clusters ?? [];
  const sortedGroupedResponses = groupedResponses.map((group) => ({
    ...group,
    responses: group.responses.sort((a, b) => b.upvotes - a.upvotes),
  }));

  const ungroupedResponsesAsGroup: ResponseCluster = {
    id: UNGROUPED_CLUSTER_ID,
    label: translate('open_ended.new_responses'),
    responses: groupedResults?.unclustered_responses ?? [],
    clustering_id: UNGROUPED_CLUSTER_ID,
    total_upvotes: (groupedResults?.unclustered_responses ?? [])
      .map((response) => response.upvotes)
      .reduce((a, b) => a + b, 0),
  };
  const isUngroupedResponsesEmpty =
    ungroupedResponsesAsGroup.responses.length === 0;

  const groups = isUngroupedResponsesEmpty
    ? groupedResponses
    : [...sortedGroupedResponses, ungroupedResponsesAsGroup];

  const shapes = getGroupsShapes(groups, fillColors, UNGROUPED_CLUSTER_ID);
  const groupsWithShapes: GroupWithShape[] = groups
    .map((group) => ({
      ...group,
      shape: shapes.get(group.id),
    }))
    .sort((a, b) => b.total_upvotes - a.total_upvotes);

  return <GroupedResultsView groups={groupsWithShapes} translate={translate} />;
};
