import {
  CANVAS_SUPPORTED_BLOCK_MARKUP,
  CANVAS_SUPPORTED_COLOR,
  CANVAS_SUPPORTED_INLINE_MARKUP,
  CANVAS_SUPPORTED_TEXT_STYLE,
  DEFAULT_EXTENSIONS,
} from '@mentimeter/tiptap';
import Placeholder from '@tiptap/extension-placeholder';
import {
  isEmptyObject,
  EditorContent as TiptapEditorContent,
  useEditor as useTiptapEditor,
  type EditorContentProps,
} from '@tiptap/react';
import { useEffect } from 'react';

interface TextMarkupRendererProps {
  content: Record<string, unknown> | string;
  placeholder?: string | undefined;
  allowBlockStyles?: boolean | undefined;
  hideColors?: boolean | undefined;
  editorContentProps?: Partial<Omit<EditorContentProps, 'ref'>> | undefined;
}

export const TextMarkupRenderer = ({
  content,
  placeholder,
  allowBlockStyles = false,
  hideColors,
  editorContentProps,
}: TextMarkupRendererProps) => {
  const editor = useTiptapEditor({
    extensions: [
      ...DEFAULT_EXTENSIONS,
      ...CANVAS_SUPPORTED_INLINE_MARKUP,
      ...(hideColors ? CANVAS_SUPPORTED_TEXT_STYLE : CANVAS_SUPPORTED_COLOR),
      ...(allowBlockStyles ? CANVAS_SUPPORTED_BLOCK_MARKUP : []),
      ...(placeholder !== undefined
        ? [Placeholder.configure({ placeholder })]
        : []),
    ],
    content: isEmptyObject(content) ? '' : content,
    editable: false,
    immediatelyRender: false,
  });

  useEffect(() => {
    // TipTap warns if we try to set an empty object as content
    if (!isEmptyObject(content)) {
      editor?.commands.setContent(content);
    }
  }, [content, editor]);

  return <TiptapEditorContent editor={editor} {...editorContentProps} />;
};
