import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { isTouchDevice } from '@mentimeter/ragnar-device';
import type { DefaultValidKeyCodesT } from '@mentimeter/hotkeys';
import { HotkeyProvider, HotkeyHandler } from '@mentimeter/hotkeys';
import { getResizedImageUrlByWidth } from './get-resized-image-url-by-width';
import { getElementPosition } from './get-element-position';
import { useWindowResize } from './use-window-resize';
import { type CoordinatesT, Pin } from './Pin';

const isMobile = isTouchDevice();

const KEYS = [
  'arrowleft',
  'arrowright',
  'arrowup',
  'arrowdown',
] as DefaultValidKeyCodesT[];
const ARROW_DELTA = 0.025;

const MAXIMUM_LARGE_IMAGE_WIDTH = 2160;
const DEFAULT_LABEL = 'Question Image';

const LOAD_TRANSITION = 'opacity 250ms ease-out';
enum Status {
  LOADING,
  LOADED,
  FAILED,
}

interface PinableImageT {
  imageUrl: string;
  onImagePin?: (arg0: CoordinatesT) => void;
}

const clamp = (value: number, min: number, max: number) =>
  Math.max(Math.min(value, max), min);

export const PinableImage = ({ imageUrl, onImagePin }: PinableImageT) => {
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [imageRect, setImageRect] = useState<null | DOMRect>(null);
  const [keysEnabled, setKeysEnabled] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState({
    x: 0.5,
    y: 0.5,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isTouchEvent, setIsTouchEvent] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);

  // imageComplete is necessary to act when ready
  const imageComplete = imageRef.current?.complete ?? false;
  const updateImageRect = useCallback(() => {
    if (!imageComplete) return;
    if (imageRef.current) {
      setImageRect(imageRef.current.getBoundingClientRect());
    }
  }, [imageRef, imageComplete]);

  useWindowResize(updateImageRect);

  // Get image dimensions after it has loaded.
  useEffect(updateImageRect, [updateImageRect]);

  // Reset loading and error state on imageUrl change.
  useEffect(() => {
    setStatus(Status.LOADING);
  }, [imageUrl]);

  const moveCoordinates = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }) => {
    if (imageRef.current && imageRect) {
      const { width, height } = imageRect;
      const position = getElementPosition(imageRef.current);
      // get the coordinates (in %) taking into account the current position of the image in the page
      setCoordinates({
        x: clamp((clientX - position.x) / width, 0, 1),
        y: clamp((clientY - position.y) / height, 0, 1),
      });
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isTouchEvent) return;
    if (status !== Status.LOADED || !imageRect || !imageRef.current) return;
    setIsDragging(true);
    moveCoordinates(e);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isTouchEvent) return;
    if (
      status !== Status.LOADED ||
      !imageRect ||
      !imageRef.current ||
      !isDragging
    )
      return;

    moveCoordinates(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    onImagePin?.(coordinates);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLImageElement>) => {
    // disable mouse events for 500ms to prevent double event handling for hybrid devices
    setIsTouchEvent(true);
    setTimeout(() => {
      setIsTouchEvent(false);
    }, 500);

    if (status !== Status.LOADED || !imageRect || !imageRef.current) return;
    setIsDragging(true);
    moveCoordinates(e.touches[0]!);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLImageElement>) => {
    if (
      status !== Status.LOADED ||
      !imageRect ||
      !imageRef.current ||
      !isDragging
    )
      return;
    moveCoordinates(e.touches[0]!);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    onImagePin?.(coordinates);
  };

  const handleKeyUp = () => {
    onImagePin?.(coordinates);
  };

  const onKey =
    ({ xDelta, yDelta }: { xDelta: number; yDelta: number }) =>
    () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      keysEnabled &&
        setCoordinates((state) => ({
          x: clamp(state.x + xDelta, 0, 1),
          y: clamp(state.y + yDelta, 0, 1),
        }));
    };

  const resizedImageUrl = getResizedImageUrlByWidth(
    imageUrl,
    MAXIMUM_LARGE_IMAGE_WIDTH,
  );

  return (
    <HotkeyProvider keyStrokes={KEYS}>
      {!isMobile && (
        <>
          <HotkeyHandler
            keyCode="arrowleft"
            onKeyDown={onKey({ xDelta: -ARROW_DELTA, yDelta: 0 })}
          />
          <HotkeyHandler
            keyCode="arrowright"
            onKeyDown={onKey({ xDelta: ARROW_DELTA, yDelta: 0 })}
          />
          <HotkeyHandler
            keyCode="arrowup"
            onKeyDown={onKey({ xDelta: 0, yDelta: -ARROW_DELTA })}
          />
          <HotkeyHandler
            keyCode="arrowdown"
            onKeyDown={onKey({ xDelta: 0, yDelta: ARROW_DELTA })}
          />
        </>
      )}
      <Box
        alignItems="center"
        style={{
          touchAction: 'none',
        }}
      >
        <Box position="relative">
          <Box
            height="100%"
            extend={() => ({
              opacity: status === Status.LOADED ? 1 : 0.2,
              transition: LOAD_TRANSITION,
            })}
          >
            <Box
              alignItems="center"
              borderRadius="lg"
              overflow="hidden"
              extend={() => ({
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
              })}
            >
              <img
                tabIndex={0}
                onFocus={() => setKeysEnabled(true)}
                onBlur={() => setKeysEnabled(false)}
                ref={imageRef}
                src={resizedImageUrl}
                alt={DEFAULT_LABEL}
                onDragStart={(e) => e.preventDefault()} // disable dragging on firefox
                draggable={false}
                // Subsequent image changes (i.e. live image changes) require the use of the onError and onLoad callbacks since the page is already loaded in the client.
                onError={() => setStatus(Status.FAILED)}
                onLoad={() => setStatus(Status.LOADED)}
                // touch devices
                onTouchStart={isMobile ? handleTouchStart : undefined}
                onTouchMove={isMobile ? handleTouchMove : undefined}
                onTouchEnd={isMobile ? handleTouchEnd : undefined} // mouse events
                onMouseMove={handleMouseMove}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp} // disable context (right click / long press) menu
                onKeyUp={handleKeyUp}
                onContextMenu={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return false;
                }} // prevent iOS context image zoom
                style={{
                  WebkitTouchCallout: 'none',
                  WebkitUserSelect: 'none',
                  userSelect: 'none',
                  maxWidth: '100%',
                  maxHeight: '45vh',
                }}
              />
            </Box>
          </Box>
          {/* TODO: make pretty / replace with something in use */}
          {status === Status.FAILED && (
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              m="auto"
              width={100}
              height={20}
            >
              error
            </Box>
          )}
          <Pin
            isDragging={isDragging}
            isMobile={isMobile}
            imageRect={imageRect}
            coordinates={coordinates}
          />
        </Box>
      </Box>
    </HotkeyProvider>
  );
};
