/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  TitleSectionContainer,
  VotingDescription,
  VotingMetaText,
  VotingTitle,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const { question_description, title_meta, image, question } = useQuestion();
  const translate = useTranslate();

  return (
    <TitleSectionContainer>
      <QuestionImage src={image} translateFn={translate} />
      <VotingMetaText meta={title_meta} />
      <VotingTitle title={question} />
      <VotingDescription
        description={question_description}
        fontSize="112.5"
        lineHeight="relaxed"
      />
    </TitleSectionContainer>
  );
}
