import { Clickable, type ClickableT } from '@mentimeter/ragnar-ui';
import React, { useCallback } from 'react';
import { CheckIcon, PlusIcon } from '@mentimeter/ragnar-visuals';
import {
  NUMBER_PICKER_TAP_AREA,
  type NumberPickerButtonProps,
} from './NumberPickerButton';

export interface ActionToggleProps
  extends Omit<ClickableT, 'onClick' | 'onChange'> {
  id: string;
  checked?: boolean | undefined;
  readOnly?: boolean | undefined;
  disabled?: boolean | undefined;
  onChange: (checked: boolean) => void;
  tapArea?: NumberPickerButtonProps['tapArea'];
}

export function ActionToggle({
  id,
  checked = false,
  readOnly = false,
  disabled = false,
  onChange,
  tapArea,
  ...props
}: ActionToggleProps) {
  const handleToggle = useCallback(() => {
    if (readOnly) {
      return;
    }
    onChange(!checked);
  }, [checked, onChange, readOnly]);

  return (
    <Clickable
      data-testid={`action-toggle-${id}`}
      data-value={checked}
      onClick={handleToggle}
      disabled={disabled}
      py="space2"
      px="space2"
      bg={disabled ? 'disabledStrong' : checked ? 'secondary' : 'bg'}
      color={disabled ? 'onDisabledStrong' : checked ? 'onSecondary' : 'text'}
      borderRadius="full"
      {...props}
      extend={({ theme }) => {
        return {
          height: 'auto',
          cursor: disabled || readOnly ? 'not-allowed' : 'pointer',
          ...((readOnly || disabled) && {
            opacity: 1,
            '@media (hover: hover)': {
              '&:hover': {
                opacity: 1,
              },
            },
          }),
          '&::after': {
            content: '""',
            position: 'absolute',
            top: `${NUMBER_PICKER_TAP_AREA}px`,
            right: `${NUMBER_PICKER_TAP_AREA}px`,
            bottom: `${NUMBER_PICKER_TAP_AREA}px`,
            left: `${NUMBER_PICKER_TAP_AREA}px`,
            ...tapArea,
          },
          '& svg g': {
            color: 'inherit',
          },
          ...props.extend?.({ theme }),
        };
      }}
    >
      {checked ? <CheckIcon color="inherit" /> : <PlusIcon color="inherit" />}
    </Clickable>
  );
}
