import React, { type MouseEvent } from 'react';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { Box, Clickable, Text } from '@mentimeter/ragnar-ui';

const fullOpacityOnHover: Extend = () => ({
  '@media (hover: hover)': {
    ':hover': {
      opacity: 1,
    },
  },
});

export type TabName = 'all' | 'my-votes';

interface TabsHeaderProps {
  onTabClick: (
    tabName: TabName,
  ) => (event: MouseEvent<HTMLButtonElement>) => void;
  labelAll: string;
  labelMyVotes: string;
}

export const TabsHeader = ({
  onTabClick,
  labelAll,
  labelMyVotes,
}: TabsHeaderProps) => {
  const [moveSliderToRight, setMoveSliderToRight] = React.useState(false);

  const handleTabClick =
    (tabName: TabName) => (event: MouseEvent<HTMLButtonElement>) => {
      const isSecondTabSelected = tabName === 'my-votes';
      setMoveSliderToRight(isSecondTabSelected);

      onTabClick(tabName)(event);
    };

  return (
    <Box
      width="100%"
      bg="bg"
      mb="space5"
      zIndex={9997}
      extend={() => ({
        position: 'sticky',
        top: 0,
      })}
    >
      <Box flexDirection="row" width="100%" mt="space6">
        <Clickable
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          pb={1}
          onClick={handleTabClick('all')}
          extend={fullOpacityOnHover}
        >
          <Text color="text" fontWeight="semiBold">
            {labelAll}
          </Text>
        </Clickable>
        <Clickable
          flex="1 1 auto"
          justifyContent="center"
          alignItems="center"
          pb={1}
          onClick={handleTabClick('my-votes')}
          data-testid="selected-votes"
          extend={fullOpacityOnHover}
        >
          <Text color="text" fontWeight="semiBold">
            {labelMyVotes}
          </Text>
        </Clickable>
      </Box>
      <Box
        width="50%"
        borderBottomWidth={1}
        borderStyle="solid"
        borderColor="primary"
        alignContent="flex-start"
        extend={() => ({
          transform: moveSliderToRight ? 'translateX(100%)' : 'translateX(0px)',
          transition: 'all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.0)',
        })}
      />
    </Box>
  );
};
