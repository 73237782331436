/**
 * Accepts an image url (string) and width (integer) and returns an new image
 * url with where the image is resized to the provided width.
 *
 * In case the provided url is null or empty, the original value is returned.
 */
export const getResizedImageUrlByWidth = (
  url: string,
  width: number,
): string | undefined => {
  try {
    const uri = new URL(url);
    uri.searchParams.set('w', width.toString());
    return uri.toString();
  } catch (e) {
    return undefined;
  }
};
