/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import {
  GameStateEnums,
  ClientErrorCodes,
  useQuizVoterGameState,
  type VoterConfig,
} from '@mentimeter/quiz';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import { Leaderboard } from '../../ui/Components/Quiz/states/Leaderboard';
import { FinalLeaderboard } from '../../ui/Components/Quiz/states/FinalLeaderboard';
import { NoLeaderboard } from '../../ui/Components/Quiz/states/NoLeaderboard';
import { Error } from '../../ui/Components/Quiz/states/Error';

const App = ({ config }: { config: VoterConfig }) => {
  const { useTheme } = useVotingContext();
  const theme = useTheme();
  const { gameState, player, getPlayerColor } = useQuizVoterGameState(
    config,
    'quiz-leaderboard',
  );

  const playerColor = getPlayerColor(theme.fillColors);

  // No leaderboard available for example a non-played quiz
  if (
    (gameState?.gameState === GameStateEnums.LEADERBOARD ||
      gameState?.gameState === GameStateEnums.FINAL_LEADERBOARD) &&
    !gameState.gameStateData.score
  ) {
    return <NoLeaderboard player={player} playerColor={playerColor} />;
  }

  switch (gameState?.gameState) {
    case GameStateEnums.LEADERBOARD: {
      return (
        <Leaderboard
          player={player}
          playerColor={playerColor}
          points={gameState.gameStateData.score?.total?.totalScore ?? 0}
          position={gameState.gameStateData.score?.total?.position ?? 0}
        />
      );
    }
    case GameStateEnums.FINAL_LEADERBOARD: {
      return (
        <FinalLeaderboard
          player={player}
          playerColor={playerColor}
          points={gameState.gameStateData.score?.total?.totalScore ?? 0}
          position={gameState.gameStateData.score?.total?.position ?? 0}
        />
      );
    }
    case GameStateEnums.ERROR: {
      return <Error code={gameState.errorCode} />;
    }
    default: {
      return (
        <Box
          alignItems="center"
          justifyContent="center"
          data-testid="quiz-loading"
        >
          <Error preLoading code={ClientErrorCodes.NO_CONNECTION} />
        </Box>
      );
    }
  }
};

export function Content() {
  return null;
}

export function Interactive() {
  const { useQuestion, usePresentation, getIdentifier } = useVotingContext();
  const { voteKey } = usePresentation();
  const { public_key } = useQuestion();
  const identifier = getIdentifier();

  const config = React.useMemo(() => {
    return {
      voteKey,
      questionPublicKey: public_key,
      identifier,
    };
  }, [identifier, public_key, voteKey]);

  const content = React.useMemo(() => {
    if (!config.identifier || !config.questionPublicKey || !config.identifier) {
      return <LoaderIcon />;
    }
    return <App config={config} />;
  }, [config]);

  return (
    <Box minHeight="40vh" width="100%" alignItems="center">
      {content}
    </Box>
  );
}
