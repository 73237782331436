/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  TitleSectionContainer,
  VotingDescription,
  VotingMetaText,
  VotingTitle,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const question = useQuestion();
  const translate = useTranslate();

  return (
    <TitleSectionContainer alignItems="center">
      <QuestionImage src={question?.image} translateFn={translate} />
      <VotingTitle title={question?.question} fontSize="400" />
      <VotingMetaText meta={question?.title_meta} />
      <VotingDescription description={question?.question_description} />
    </TitleSectionContainer>
  );
}
