import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Text, type TextT } from '@mentimeter/ragnar-ui';

interface VotingMetaTextProps extends TextT {
  meta?: string | undefined;
}

export function VotingMetaText({ meta, ...props }: VotingMetaTextProps) {
  const {
    ThemeStyled: { StringParser },
  } = useVotingContext();

  if (!meta) return null;

  return (
    meta && (
      <Text
        as="p"
        fontSize="100"
        lineHeight="125"
        fontWeight="regular"
        color="textWeaker"
        mb="space2"
        {...props}
      >
        <StringParser disableLineBreaks={false} source={meta} />
      </Text>
    )
  );
}
