// The key is formed as lowest_level_highest_level_rangeSize, and enables quick and easy lookup as all that information
// should be present from the place (currently only ChoiceSliders) that needs the full array of levels.
//SLIDE MOVE - This should be in the shared-module folder
export const LIKERT_SCALES: Record<string, string[]> = {
  totally_unacceptable_perfectly_acceptable_7: [
    'Totally unacceptable',
    'Unacceptable',
    'Slightly unacceptable',
    'Neutral',
    'Slightly acceptable',
    'Acceptable',
    'Perfectly Acceptable',
  ],
  absolutely_inappropriate_absolutely_appropriate_7: [
    'Absolutely inappropriate',
    'Inappropriate',
    'Slightly inappropriate',
    'Neutral',
    'Slightly appropriate',
    'Appropriate',
    'Absolutely appropriate',
  ],
  not_at_all_important_extremely_important_7: [
    'Not at all important',
    'Low importance',
    'Slightly important',
    'Neutral',
    'Moderately important',
    'Very important',
    'Extremely important',
  ],
  strongly_disagree_strongly_agree_7: [
    'Strongly disagree',
    'Disagree',
    'Somewhat disagree',
    'Neither agree or disagree',
    'Somewhat agree',
    'Agree',
    'Strongly agree',
  ],
  never_true_always_true_7: [
    'Never true',
    'Rarely true',
    'Sometimes but infrequently true',
    'Neutral',
    'Sometimes true',
    'Usually true',
    'Always true',
  ],
  very_untrue_of_me_very_true_of_me_7: [
    'Very untrue of me',
    'Untrue of me ',
    'Somewhat untrue of me',
    'Neutral',
    'Somewhat true of me',
    'True of me',
    'Very true of me',
  ],
  very_untrue_of_what_i_believe_very_true_of_what_i_believe_7: [
    'Very untrue of what I believe ',
    'Untrue of what I believe',
    'Somewhat untrue of what I believe',
    'Neutral',
    'Somewhat true of what I believe',
    'True of what I believe',
    'Very true of what I believe',
  ],
  not_a_priority_essential_priority_7: [
    'Not a priority',
    'Low priority',
    'Somewhat priority ',
    'Neutral',
    'Moderate Priority ',
    'High priority',
    'Essential priority',
  ],
  not_at_all_concerned_extremely_concerned_5: [
    'Not at all concerned',
    'Slightly concerned',
    'Somewhat concerned ',
    'Moderately concerned ',
    'Extremely concerned',
  ],
  not_a_priority_essential_5: [
    'Not a priority',
    'Low priority',
    'Medium priority ',
    'High priority',
    'Essential',
  ],
  not_at_all_a_problem_serious_problem_4: [
    'Not at all a problem',
    'Minor problem ',
    'Moderate problem',
    'Serious problem',
  ],
  no_affect_major_affect_5: [
    'No affect',
    'Minor affect ',
    'Neutral ',
    'Moderate affect ',
    'Major affect',
  ],
  would_not_consider_definitely_consider_3: [
    'Would not consider',
    'Might or might not consider',
    'Definitely consider',
  ],
  strongly_oppose_strongly_favor_5: [
    'Strongly oppose',
    'Somewhat oppose ',
    'Neutral',
    'Somewhat favor',
    'Strongly favor',
  ],
  not_probable_very_probable_5: [
    'Not probable',
    'Somewhat improbable ',
    'Neutral',
    'Somewhat probable',
    'Very probable',
  ],
  strongly_disagree_strongly_agree_5: [
    'Strongly disagree',
    'Disagree',
    'Neither agree nor disagree',
    'Agree',
    'Strongly agree',
  ],
  very_undesirable_very_desirable_5: [
    'Very undesirable ',
    'Undesirable',
    'Neutral',
    'Desirable',
    'Very desirable ',
  ],
  no_and_not_considered_yes_3: [
    'No and not considered',
    'No but considered',
    'Yes',
  ],
  never_always_5: ['Never', 'Rarely', 'Sometimes ', 'Often', 'Always'],
  never_a_great_deal_5: [
    'Never',
    'Rarely',
    'Occasionally',
    'A moderate amount ',
    'A great deal',
  ],
  never_every_time_5: [
    'Never',
    'Almost never',
    'Occasionally / Sometimes ',
    'Almost every time',
    'Every time',
  ],
  never_every_time_7: [
    'Never',
    'Rarely',
    'Occasionally',
    'Sometimes',
    'Frequently',
    'Usually',
    'Every time',
  ],
  never_use_frequently_use_5: [
    'Never use',
    'Almost never',
    'Occasionally / Sometimes',
    'Almost every time',
    'Frequently use',
  ],
  not_at_all_familiar_extremely_familiar_5: [
    'Not at all familiar',
    'Slightly familiar',
    'Somewhat familiar',
    'Moderately familiar',
    'Extremely familiar',
  ],
  not_at_all_aware_extremely_aware_5: [
    'Not at all aware',
    'Slightly aware',
    'Somewhat aware',
    'Moderately aware',
    'Extremely aware',
  ],
  very_difficult_very_easy_5: [
    'Very difficult ',
    'Difficult',
    'Neutral',
    'Easy',
    'Very easy',
  ],
  extremely_unlikely_extremely_likely_5: [
    'Extremely unlikely',
    'Unlikely',
    'Neutral',
    'Likely',
    'Extremely likely',
  ],
  detracted_very_little_detracted_very_much_5: [
    'Detracted very little',
    ' ',
    'Neutral',
    ' ',
    'Detracted very much',
  ],
  very_negative_very_positive_5: [
    'Very negative',
    ' ',
    'Neutral',
    ' ',
    'Very positive',
  ],
  not_a_barrier_extreme_barrier_4: [
    'Not a barrier',
    'Somewhat of a barrier',
    'Moderate barrier',
    'Extreme barrier',
  ],
  very_dissatisfied_very_satisfied_5: [
    'Very dissatisfied ',
    'Dissatisfied',
    'Unsure',
    'Satisfied',
    'Very satisfied',
  ],
  not_at_all_satisfied_extremely_satisfied_5: [
    'Not at all satisfied',
    'Slightly satisfied',
    'Moderately satisfied ',
    'Very satisfied',
    'Extremely satisfied',
  ],
  completely_dissatisfied_completely_satisfied_7: [
    'Completely dissatisfied ',
    'Mostly dissatisfied',
    'Somewhat dissatisfied ',
    'Neither satisfied or dissatisfied',
    'Somewhat satisfied ',
    'Mostly satisfied',
    'Completely satisfied',
  ],
  poor_excellent_5: ['Poor', 'Fair', 'Good', 'Very good ', 'Excellent'],
  much_worse_much_better_5: [
    'Much worse',
    'Somewhat worse ',
    'About the same ',
    'Somewhat better ',
    'Much better',
  ],
  not_at_all_responsible_completely_responsible_4: [
    'Not at all responsible',
    'Somewhat responsible ',
    'Mostly responsible',
    'Completely responsible',
  ],
  not_at_all_influential_extremely_influential_5: [
    'Not at all influential',
    'Slightly influential',
    'Somewhat influential ',
    'Very influential',
    'Extremely influential',
  ],
};
