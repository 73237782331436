import * as React from 'react';
import {
  Clickable,
  Image,
  ModalTrigger,
  ModalRoot,
  Box,
  ModalContainer,
  ModalDismiss,
  ModalBody,
  ModalHeader,
  ScreenReaderOnly,
} from '@mentimeter/ragnar-ui';
import { CrossIcon } from '@mentimeter/ragnar-visuals';

export interface OptionImageProps {
  smallSrc: string;
  largeSrc: string;
  altDescription?: string | undefined;
  dismissButtonText: string;
  showImageText: string;
  imageRenderSize?: number | string;
}

export const OptionImage = ({
  smallSrc,
  largeSrc,
  altDescription,
  dismissButtonText,
  showImageText,
  imageRenderSize = 72,
}: OptionImageProps) => {
  return (
    <ModalRoot>
      <ModalTrigger>
        <Box justifyContent="center">
          <Clickable
            type="button"
            aria-label={showImageText}
            borderRadius="2xl"
            overflow="hidden"
          >
            <Image
              src={smallSrc}
              alt={altDescription}
              height={imageRenderSize}
              width={imageRenderSize}
            />
          </Clickable>
        </Box>
      </ModalTrigger>
      <ModalContainer p="space1.5">
        <ScreenReaderOnly>
          <ModalHeader />
        </ScreenReaderOnly>
        <ModalDismiss>
          <Clickable
            aria-label={dismissButtonText}
            position="absolute"
            p="space2"
            top={0}
            right={0}
            type="button"
            zIndex={2}
            mr="space4"
            mt="space4"
            bg="bg"
            borderRadius="full"
          >
            <CrossIcon size={3} color="text" />
          </Clickable>
        </ModalDismiss>

        <ModalBody px="space0" pb="space0" pt="space0" borderRadius="xl">
          <Box borderRadius="xl" overflow="hidden">
            <Image src={largeSrc} alt={altDescription} />
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
};
