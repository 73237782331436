'use client';

import { Provider as ReduxProvider } from 'react-redux';
import React from 'react';
import { getRegionByVoteKey } from '@mentimeter/region';
import { setClientFallbackRegion } from '@mentimeter/http-clients';
import type { VotingSeries as Series } from '@mentimeter/http-clients';
import { createStore } from '../../redux-store';
import { fetchSeriesSuccess } from '../../reducers/seriesSlice';
import { identifierClient } from '../../utils/identifier';

export function VotingWrapper({
  series,
  voteKey,
  children,
}: { series: Series; voteKey: string; children: React.ReactNode }) {
  const store = React.useMemo(() => {
    const newStore = createStore({});
    newStore.dispatch(fetchSeriesSuccess(series));
    return newStore;
  }, [series]);

  React.useEffect(() => {
    const region = getRegionByVoteKey(voteKey);
    setClientFallbackRegion('voting', region);
    identifierClient.setRegion(region);
  }, [voteKey]);

  return (
    <ReduxProvider store={store} key="provider">
      {children}
    </ReduxProvider>
  );
}
