/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { Box, Button, Form, TextInput } from '@mentimeter/ragnar-ui';
import {
  ClientErrorCodes,
  GameStateEnums,
  useQuizVoterGameState,
  type VoterConfig,
} from '@mentimeter/quiz';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { Lobby } from '../../ui/Components/Quiz/states/Lobby';
import { GetReady } from '../../ui/Components/Quiz/states/GetReady';
import { Countdown } from '../../ui/Components/Quiz/states/Countdown';
import { WaitForResults } from '../../ui/Components/Quiz/states/WaitForResults';
import { Results } from '../../ui/Components/Quiz/states/Results';
import { Error } from '../../ui/Components/Quiz/states/Error';

const SubmitForm = ({ onSubmit }: { onSubmit: (answer: string) => void }) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const [answer, setAnswer] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  return (
    <Box alignItems="center">
      <Form
        width="100%"
        alignItems="center"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(answer);
          setSubmitted(true);
        }}
      >
        <TextInput
          mt="space8"
          name="quiz-open-input"
          disabled={submitted}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <Button
          size="large"
          mt="space6"
          type="submit"
          variant="primary"
          state={submitted ? 'loading' : undefined}
          disabled={submitted}
        >
          {translate('buttons.submit')}
        </Button>
        <VotingConfirmationModal
          id="skip-vote-modal-choices"
          showModal={showModal}
          onConfirm={() => onSubmit('')}
          onDismiss={() => setShowModal(false)}
          title={translate('quiz_open.skip_question')}
          bodyText={translate('quiz_open.no_answer_warning')}
          confirmButtonText={translate('buttons.ok')}
          dismissButtonText={translate('buttons.cancel')}
        />
      </Form>
      <Button
        size="large"
        mt="space4"
        type="button"
        variant="subtle"
        disabled={submitted}
        onClick={() => setShowModal(true)}
      >
        {translate('buttons.skip_question')}
      </Button>
    </Box>
  );
};

const App = ({ config }: { config: VoterConfig }) => {
  const { useQuestion, useTheme, useQuiz } = useVotingContext();
  const { totalCount, currentIndex } = useQuiz();
  const theme = useTheme();
  const {
    question,
    time_based_scoring,
    image,
    question_description,
    question_styled,
  } = useQuestion();
  const {
    updatePlayer,
    submitOpenAnswer,
    player,
    gameState,
    timeOffset = 0,
    getPlayerColor,
    connected,
  } = useQuizVoterGameState(config, 'quiz-open');

  const playerColor = getPlayerColor(theme.fillColors);

  React.useLayoutEffect(() => {
    // A11y: Focus on heading when step is changed
    document.querySelector('h1')?.focus();
  }, [gameState?.gameState]);

  switch (gameState?.gameState) {
    case GameStateEnums.LOBBY: {
      return (
        <Lobby
          connected={Boolean(connected)}
          total={totalCount}
          current={currentIndex + 1}
          player={player}
          playerColor={playerColor}
          updatePlayer={updatePlayer}
          timeBasedScoring={time_based_scoring}
        />
      );
    }
    case GameStateEnums.GET_READY: {
      return <GetReady timeBasedScoring={time_based_scoring} />;
    }
    case GameStateEnums.COUNTDOWN: {
      return (
        <Countdown
          timeBasedScoring={time_based_scoring}
          question={question}
          questionStyled={question_styled}
          description={question_description}
          imageUrl={image?.presets.medium.url ?? undefined}
          timeOffset={timeOffset}
          startAt={gameState.gameStateData.startAt}
          endAt={gameState.gameStateData.endAt}
          playerColor={playerColor}
        >
          <SubmitForm onSubmit={(answer) => submitOpenAnswer(answer)} />
        </Countdown>
      );
    }
    case GameStateEnums.QUESTION: {
      return (
        <WaitForResults
          player={player}
          playerColor={playerColor}
          elapsedTime={gameState.gameStateData.elapsedTime}
        />
      );
    }
    case GameStateEnums.RESULT: {
      return (
        <Results
          player={player}
          playerColor={playerColor}
          result={
            gameState.gameStateData.score?.question?.markedCorrect ?? null
          }
        />
      );
    }
    case GameStateEnums.ERROR: {
      return <Error code={gameState.errorCode} />;
    }
    default: {
      return (
        <Box
          alignItems="center"
          justifyContent="center"
          data-testid="quiz-loading"
        >
          <Error preLoading code={ClientErrorCodes.NO_CONNECTION} />
        </Box>
      );
    }
  }
};

export function Content() {
  return null;
}

export function Interactive() {
  const { useQuestion, usePresentation, getIdentifier } = useVotingContext();
  const { voteKey } = usePresentation();
  const { public_key } = useQuestion();
  const identifier = getIdentifier();

  const config = React.useMemo(() => {
    return {
      voteKey,
      questionPublicKey: public_key,
      identifier,
    };
  }, [identifier, public_key, voteKey]);

  const content = React.useMemo(() => {
    if (!config.identifier || !config.questionPublicKey || !config.identifier) {
      return <LoaderIcon />;
    }
    return <App config={config} />;
  }, [config]);

  return (
    <Box minHeight="40vh" width="100%" alignItems="center">
      {content}
    </Box>
  );
}
