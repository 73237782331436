/* eslint-disable menti-react/filename-convention--jsx */
import { Box } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const {
    image,
    title_meta: meta,
    question,
    question_styled,
    question_description: description,
  } = useQuestion();
  const translate = useTranslate();

  return (
    <Box
      width="100%"
      alignItems="stretch"
      aria-atomic
      aria-live="polite"
      mb={3}
    >
      <TitleSectionWithImage
        image={image}
        meta={meta}
        title={question}
        titleStyled={question_styled}
        description={description || translate('qfa.click_participate')}
      />
    </Box>
  );
}
