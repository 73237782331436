import React, { type PropsWithChildren } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { animated, useSpring, type SpringValues } from '@react-spring/web';

export interface ExpandingAnimationProps {
  shouldExpand: boolean;
}
export const ExpandingAnimation = ({
  shouldExpand,
  children,
}: PropsWithChildren<ExpandingAnimationProps>) => {
  const [style] = useSpring(
    () => ({
      gridTemplateRows: shouldExpand ? '1fr' : '0fr',
    }),
    [shouldExpand],
  );

  return (
    <Box
      as={animated.div}
      display="grid"
      style={style as SpringValues}
      overflow="hidden"
      aria-hidden={!shouldExpand}
    >
      <Box overflow="hidden">{children}</Box>
    </Box>
  );
};
