/* eslint-disable menti-react/filename-convention--jsx */
import { Box, Text } from '@mentimeter/ragnar-ui';

export function Content() {
  return (
    <Box width="100%" alignItems="center" mb={3}>
      <Text color="text" fontSize={3} my={4} textAlign="center">
        Look at the presentation to follow the speaker.
      </Text>
    </Box>
  );
}
