import React from 'react';
import type { Connection } from '../Connection';

// Delay to not be too trigger happy with disconnects
export const DISCONNECTION_DELAY = 2500;

export const useQuizStatus = (connection: Connection | null) => {
  const [connected, setConnected] = React.useState(
    Boolean(connection?.socket.connected),
  );
  const [connectedWithDelay, setConnectedWithDelay] = React.useState(
    Boolean(connection?.socket.connected),
  );
  const [timeOffset, setTimeOffset] = React.useState<number | undefined>(
    connection?.timeSync?.getReady()
      ? connection?.timeSync?.getTimeOffset()
      : undefined,
  );
  React.useEffect(() => {
    if (connection?.timeSync) {
      const fn = ({ timeOffset }: { timeOffset: number; latency: number }) => {
        setTimeOffset(timeOffset);
      };
      connection?.timeSync.addUpdateListener(fn);
      return () => {
        connection?.timeSync.removeUpdateListener(fn);
      };
    }
    return () => {};
  }, [connection?.timeSync]);

  React.useEffect(() => {
    if (!connection?.socket) return;
    let timer: ReturnType<typeof setTimeout>;
    const onConnect = () => {
      clearTimeout(timer);
      setConnected(true);
      setConnectedWithDelay(true);
    };
    const onDisconnect = () => {
      setConnected(false);
      timer = setTimeout(() => {
        setConnectedWithDelay(false);
      }, DISCONNECTION_DELAY);
    };
    connection.socket.on('connect', onConnect);
    connection.socket.on('disconnect', onDisconnect);
    return () => {
      clearTimeout(timer);
      connection.socket.off('connect', onConnect);
      connection.socket.off('disconnect', onDisconnect);
    };
  }, [connection?.socket]);

  return { connectedWithDelay, connected, timeOffset };
};
