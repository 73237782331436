import React from 'react';
import type { ResponseCluster } from '@mentimeter/http-clients';
import type { TranslationFn } from '@mentimeter/question-modules-types';
import { ExpandableList } from '@mentimeter/voting-ui';
import { GroupedResponsesCard } from './GroupedResponsesCard';

interface ResponsesGroupProps {
  group: ResponseCluster;
  tagLabel?: string | undefined;
  groupShape: React.ReactNode;
  isExpanded: boolean;
  totalUpvotes: number;
  handleGroupClick: (id: string) => void;
  responseRenderer: (
    response: ResponseCluster['responses'][0],
  ) => React.ReactNode;
  translate: TranslationFn;
}
export const ResponsesGroup = ({
  group,
  tagLabel,
  groupShape,
  isExpanded,
  totalUpvotes,
  handleGroupClick,
  responseRenderer,
  translate,
}: ResponsesGroupProps) => {
  return (
    <GroupedResponsesCard
      key={group.id}
      groupId={group.id}
      label={group.label}
      tagLabel={tagLabel}
      groupShape={groupShape}
      numberOfVotes={totalUpvotes}
      isExpanded={isExpanded}
      onClick={handleGroupClick}
      translate={translate}
    >
      <ExpandableList
        show={isExpanded}
        items={group.responses}
        renderItem={responseRenderer}
      />
    </GroupedResponsesCard>
  );
};
