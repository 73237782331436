import React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';

export interface ActionCardProps extends BoxT {
  disabled?: boolean | undefined;
}

export function ActionCard({
  disabled = false,
  children,
  ...rest
}: ActionCardProps) {
  if (children === undefined || children === '') {
    return null;
  }

  return (
    <Box
      position="relative"
      borderRadius="full"
      borderStyle="solid"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      bg={disabled ? 'disabledStrong' : 'secondary'}
      color={disabled ? 'onDisabledStrong' : 'onSecondary'}
      aria-disabled={disabled}
      {...rest}
      extend={({ theme }) => {
        return {
          whiteSpace: 'nowrap',
          textDecoration: 'none',
          transition: `${theme.durations[1]}s ease`,
          ...rest.extend?.({ theme }),
        };
      }}
    >
      {children}
    </Box>
  );
}
