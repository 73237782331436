/* eslint-disable menti-react/filename-convention--jsx */
import React, { Fragment, useCallback, useState } from 'react';
import { Box, Form } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Question, QuestionChoice } from '@mentimeter/http-clients';
import type { VisualizationColors } from '@mentimeter/ragnar-colors';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { Option } from './Option';
import type { Axis } from './types';

type VoteValue = Record<Axis, number>;

interface ValidatedVote {
  skipped: boolean;
  value: VoteValue;
}

type ValidatedVotes = Record<QuestionChoice['id'], ValidatedVote>;

export interface VotingComponentProps {
  question: Omit<Question, 'show_all_bullets'>;
  theme: VisualizationColors;
  onSubmit: (votes: ValidatedVotes) => void;
}

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      description={question.question_description}
      title={question.question}
      meta={question.title_meta}
      image={question.image}
    />
  );
}

type ResponseValues = [number | '', number | ''];

export function Interactive() {
  const { useQuestion, useActions, useTheme, useTranslate } =
    useVotingContext();
  const { skip: skipVote, vote: submitVote } = useActions();
  const theme = useTheme();
  const question = useQuestion();
  const translate = useTranslate();
  const { choices, dimensions, range, hide_skip: hideSkip } = question;
  const [values, setValues] = useState<ValidatedVotes>({});
  const [shouldShowConfirmSubmitModal, setShouldShowConfirmSubmitModal] =
    useState(false);

  const handleSubmit = useCallback(
    (votes: ValidatedVotes) => {
      // Edge case: voting on a question without choices
      if (question.choices.length === 0) {
        skipVote();
        return;
      }

      const actualVotes: Record<QuestionChoice['id'], ResponseValues> = {};
      question.choices.map((c) => {
        const x = votes[c.id]?.value?.x ?? '';
        const y = votes[c.id]?.value?.y ?? '';
        const value = (
          votes[c.id]?.skipped ? ['', ''] : [x, y]
        ) satisfies ResponseValues;
        actualVotes[c.id] = value;
      });
      submitVote(actualVotes, false);
    },
    [question.choices, skipVote, submitVote],
  );

  function isValidVote(voteValue: number | undefined) {
    if (voteValue === undefined) {
      return false;
    }
    return voteValue >= range.min && voteValue <= range.max;
  }

  function getVoteValueIfValid(
    voteValue: VoteValue | undefined,
  ): VoteValue | undefined {
    if (isValidVote(voteValue?.x) && isValidVote(voteValue?.y)) {
      return voteValue;
    }
    return undefined;
  }

  // The validated vote that is updated to number of choices and within range
  const votes = choices.reduce<ValidatedVotes>((prev, c) => {
    const newValue = getVoteValueIfValid(values[c.id]?.value) ?? {
      x: range.min,
      y: range.min,
    };
    return {
      ...prev,
      [c.id]: {
        value: newValue,
        skipped: values[c.id]?.skipped || false,
      },
    };
  }, {});

  function onChange(id: number, input: string, axis: Axis = 'x') {
    const currentValue = getVoteValueIfValid(votes[id]?.value);
    if (!currentValue) {
      return;
    }
    const newValue = { ...currentValue, [axis]: parseInt(input, 10) };
    const validatedNewValue = getVoteValueIfValid(newValue);
    if (validatedNewValue) {
      setValues((state) => ({
        ...state,
        [id]: { value: validatedNewValue, skipped: false },
      }));
    }
  }

  function onChangeOptionSkip(id: number) {
    setValues((state) => ({
      ...state,
      [id]: {
        value: votes[id]!.value,
        skipped: !votes[id]!.skipped,
      },
    }));
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const isEmpty = Object.keys(values).length === 0;
      if (isEmpty) {
        setShouldShowConfirmSubmitModal(true);
      } else {
        handleSubmit(votes);
      }
    },
    [values, handleSubmit, votes],
  );

  return (
    <Form onSubmit={onSubmit} width="100%">
      <Box width="100%" mb={3}>
        {choices.map((c, index) => {
          return (
            <Fragment key={c.id}>
              <Option
                index={index}
                id={c.id}
                // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                value={votes[c.id] && votes[c.id].value}
                subType={question.sub_type}
                label={c.label}
                labels={dimensions}
                min={range.min}
                max={range.max}
                onChange={onChange}
                onSkip={onChangeOptionSkip}
                skippable={!hideSkip}
                skipped={votes[c.id]?.skipped ?? false}
                theme={theme}
              />
            </Fragment>
          );
        })}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="submit-vote-modal-rating"
        showModal={shouldShowConfirmSubmitModal}
        onConfirm={() => handleSubmit(votes)}
        onDismiss={() => setShouldShowConfirmSubmitModal(false)}
        title={translate('scales.confirmation_modal_title')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
