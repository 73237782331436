const STEP_LABELS = [
  'submit-answers',
  'vote-on-answers',
  'view-results',
] as const;

type StepLabel = (typeof STEP_LABELS)[number];

export const getStepLabel = (
  votingOnAnswers: boolean,
  step: number,
): StepLabel =>
  votingOnAnswers ? (STEP_LABELS[step] ?? 'submit-answers') : 'submit-answers';
