import { useVotingContext } from '@mentimeter/question-modules-contexts';
import React, { useCallback } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import type { OpenVotesT } from '@mentimeter/question-modules-types';
import type { GroupWithShape } from '../types';
import { WaitingView } from '../WaitingView';
import { type TabName } from './TabsHeader';
import type { useUpvotes, Upvotes } from './useUpvotes';
import { BottomBar } from './BottomBar';
import { ResponsesWrapper } from './ResponsesWrapper';
import { GroupedResponsesWrapper } from './GroupedResponsesWrapper';

type InfoModalType = keyof typeof MODAL_CONTENT_MAP;

const MODAL_CONTENT_MAP = {
  closed: {
    id: 'confirmation-modal-info-closed',
    heading: '',
    description: '',
  },
  'could-not-submit': {
    id: 'confirmation-modal-could-not-submit',
    heading: 'open_ended.could_not_submit_votes',
    description: 'open_ended.used_all_votes',
  },
  'used-all-votes': {
    id: 'confirmation-modal-used-all-votes',
    heading: 'open_ended.used_all_votes',
    description: 'open_ended.unselect_votes',
  },
  'did-not-vote': {
    id: 'confirmation-modal-did-not-vote',
    heading: 'open_ended.not_selected_anything',
    description: 'open_ended.click_on_response_to_vote',
  },
  'unexpected-problem': {
    id: 'confirmation-modal-unexpected-problem',
    heading: 'open_ended.could_not_submit_votes',
    description: 'messages.unexpected_problem',
  },
} satisfies Record<
  string,
  { id: string; heading: string; description: string }
>;

export type Answers =
  | {
      type: 'responses';
      responses: OpenVotesT[];
    }
  | {
      type: 'groups';
      groups: GroupWithShape[];
    };

export interface UpvotingViewProps {
  maxVotes: number;
  responses: Answers;
  multipleUpvotesEnabled: boolean;
  submitUpvotes: (upvotes: Upvotes) => Promise<void>;
  upvoting: ReturnType<typeof useUpvotes>;
}

export const UpvotingView = ({
  maxVotes,
  multipleUpvotesEnabled,
  responses,
  submitUpvotes,
  upvoting,
}: UpvotingViewProps) => {
  const { useTranslate, useTrack } = useVotingContext();
  const track = useTrack();
  const translate = useTranslate();

  const [isWaiting, setIsWaiting] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState<TabName>('all');

  const [infoModal, setInfoModal] = React.useState<InfoModalType>('closed');
  const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] =
    React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const hasMultipleUpvotes = multipleUpvotesEnabled && maxVotes > 1;

  const { upvotes, onUpvote, totalUpvotes } = upvoting;

  const handleUpvote = (id: string, voteCount: number) => {
    const currentVoteCount = upvotes[id] || 0;

    const hasUsedAllVotes =
      totalUpvotes - currentVoteCount + voteCount > maxVotes;
    if (hasUsedAllVotes) {
      setInfoModal('used-all-votes');
      return;
    }

    onUpvote(id, voteCount);
  };

  const handleSubmitUpvotes = useCallback(async () => {
    setIsLoading(true);
    try {
      await submitUpvotes(upvotes);
      track({ event: 'Upvoted open ended' });
      setIsWaiting(true);
    } catch (e: any) {
      if (e === 'duplicated' || e === 'used_all_upvotes') {
        setInfoModal('could-not-submit');
      } else {
        setInfoModal('unexpected-problem');
      }
    } finally {
      setIsLoading(false);
    }
  }, [submitUpvotes, track, upvotes]);

  const onSubmit = useCallback(async () => {
    if (totalUpvotes === 0) {
      setInfoModal('did-not-vote');
      return;
    }
    if (totalUpvotes < maxVotes) {
      setShowSubmitConfirmationModal(true);
      return;
    }

    handleSubmitUpvotes();
  }, [maxVotes, handleSubmitUpvotes, totalUpvotes]);

  const onTabClick = useCallback(
    (tabName: TabName) => () => {
      if (tabName === currentTab) {
        return;
      }
      setCurrentTab(tabName);
    },
    [currentTab],
  );

  if (isWaiting) {
    return (
      <WaitingView>{translate('open_ended.waiting_for_results')}</WaitingView>
    );
  }

  return (
    <Box justifyContent="center" height="100%" width="100%">
      <Box bg="bg" height="100%" width="100%" alignItems="stretch">
        {responses.type === 'responses' && (
          <ResponsesWrapper
            onTabClick={onTabClick}
            currentTab={currentTab}
            handleUpvote={handleUpvote}
            upvotes={upvotes}
            hasMultipleUpvotes={hasMultipleUpvotes}
            responses={responses.responses}
          />
        )}
        {responses.type === 'groups' && (
          <GroupedResponsesWrapper
            onTabClick={onTabClick}
            currentTab={currentTab}
            totalUpvotes={totalUpvotes}
            handleUpvote={handleUpvote}
            upvotes={upvotes}
            hasMultipleUpvotes={hasMultipleUpvotes}
            groups={responses.groups}
          />
        )}
      </Box>
      <BottomBar
        votesLeft={maxVotes - totalUpvotes}
        isLoading={isLoading}
        onSubmit={onSubmit}
        translate={translate}
      />
      <VotingConfirmationModal
        id={MODAL_CONTENT_MAP[infoModal].id}
        title={translate(MODAL_CONTENT_MAP[infoModal].heading)}
        bodyText={translate(MODAL_CONTENT_MAP[infoModal].description)}
        showModal={infoModal !== 'closed'}
        confirmButtonText={translate('buttons.ok')}
        onConfirm={() => setInfoModal('closed')}
        onDismiss={() => setInfoModal('closed')}
      />
      <VotingConfirmationModal
        id="confirmation-modal-votes-left"
        title={translate('open_ended.votes_left_modal_title')}
        bodyText={translate('open_ended.votes_left_modal_description')}
        showModal={showSubmitConfirmationModal}
        confirmButtonText={translate('buttons.go_back')}
        onConfirm={() => setShowSubmitConfirmationModal(false)}
        dismissButtonText={translate(
          'open_ended.confirmation_modal.submit_anyway',
        )}
        onDismiss={handleSubmitUpvotes}
        onOpenChange={setShowSubmitConfirmationModal}
      />
    </Box>
  );
};
