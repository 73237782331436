import Mathematics from '@tiptap-pro/extension-mathematics';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Color from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import TextExtension from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { ExtendedHeading } from './extensions/ExtendedHeading';
import { SanitizedLink } from './extensions/SanitizedLink';
import { Small } from './extensions/Small';

export const DEFAULT_EXTENSIONS = [Document, TextExtension, Paragraph];

export const CANVAS_SUPPORTED_TEXT_STYLE = [TextStyle];

export const CANVAS_SUPPORTED_COLOR = [
  ...CANVAS_SUPPORTED_TEXT_STYLE,
  Color.configure({ types: ['textStyle', 'listItem'] }),
];

export const CANVAS_SUPPORTED_INLINE_MARKUP = [
  Bold,
  Strike,
  Italic,
  Underline,
  Code,
  SanitizedLink,
  Mathematics.configure({
    regex: /(?:^|\s)\$([^$]+)\$(?=\s|$|[^\w])/g,
  }),
];

export const CANVAS_SUPPORTED_BLOCK_MARKUP = [
  ExtendedHeading.configure({ levels: [1, 2, 3] }),
  BulletList.configure({ keepAttributes: true }),
  OrderedList.configure({ keepAttributes: true }),
  ListItem,
  Blockquote,
  CodeBlock,
  Small,
  HardBreak,
];
