import React from 'react';
import { Text, type TextT } from '@mentimeter/ragnar-ui';

export interface ActionCardTextProps extends TextT {
  disabled?: boolean | undefined;
}

export function ActionCardText({
  id: key,
  disabled,
  children,
  ...rest
}: ActionCardTextProps) {
  const id = key ? `action-text-${key}` : undefined;
  return (
    <Text
      id={id}
      data-testid={id}
      fontWeight="semiBold"
      fontFamily="body"
      textAlign="center"
      fontSize="87.5"
      py="space1"
      px="space2"
      lineHeight="normal"
      color={disabled ? 'onDisabledStrong' : 'onSecondary'}
      {...rest}
      extend={({ theme }) => {
        return {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontVariantNumeric: 'tabular-nums',
          '> svg g': {
            color: disabled
              ? theme.colors.onDisabledStrong
              : theme.colors.onPrimary,
          },
          ...rest.extend,
        };
      }}
    >
      {children}
    </Text>
  );
}
