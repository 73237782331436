// Needs to be a type instead of interface as the interface can't satisfy the generic used with useSlideState hook
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type VideoState = {
  isOpen?: boolean;
  isPlaying?: boolean;
  isLoading?: boolean;
};

const youtubeRegex =
  /^(?:https?:)?\/?\/?(?:(?:www|m)\.)?(?:(?:youtube(?:-nocookie)?\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;
const vimeoRegex =
  /^(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

const vimeoStartTimeMinutesRegex = /(?:#t=)(\d+)m/;
const vimeoStartTimeSecondsRegex = /(?:#t=|m)(\d+)s/;
const youtubeStartTimeRegex = /(?:t=|start=)(\d+)/;
const vimeoHashRegex = /\d+(?:\?h=|\/)([a-f\d]+)/;

export const isValidYoutubeUrl = (url: string): boolean => {
  return youtubeRegex.test(url);
};

export const isValidVimeoUrl = (url: string): boolean => {
  return vimeoRegex.test(url);
};

export const getVimeoStartTimeFromUrl = (url: string): number => {
  const minutesMatch = url.match(vimeoStartTimeMinutesRegex);
  const secondsMatch = url.match(vimeoStartTimeSecondsRegex);
  if (!minutesMatch && !secondsMatch) return 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[1] ?? '0') : 0;
  const seconds = secondsMatch ? parseInt(secondsMatch[1] ?? '0') : 0;
  return minutes * 60 + seconds;
};

export const getYoutubeStartTimeFromUrl = (url: string): number => {
  const match = url.match(youtubeStartTimeRegex);
  const startTime = match ? parseInt(match[1] ?? '0') : 0;
  return startTime;
};

export const getYoutubeIdFromUrl = (url: string): string | null => {
  try {
    const match = url.match(youtubeRegex);
    const videoId = match && match[1] ? match[1] : null;
    return videoId;
  } catch (error) {
    return null;
  }
};

export const getVimeoIdFromUrl = (url: string): number | null => {
  try {
    const match = url.match(vimeoRegex);
    const videoId = match && match[1] ? parseInt(match[1]) : null;
    return videoId;
  } catch (error) {
    return null;
  }
};

/**
 * Gets hash param needed for unlisted videos
 */
export const getVimeoHashFromUrl = (url: string): string | null => {
  try {
    const match = url.match(vimeoHashRegex);
    const hash = match && match[1] ? match[1] : null;
    return hash;
  } catch (error) {
    return null;
  }
};

type VideoType = 'youtube' | 'vimeo';
export const getVideoTypeFromUrl = (url: string): VideoType | null => {
  if (isValidYoutubeUrl(url)) {
    return 'youtube';
  }
  if (isValidVimeoUrl(url)) {
    return 'vimeo';
  }
  return null;
};

export const getValidVideoId = (url: string): string | number | null => {
  if (!isValidVimeoUrl(url) && !isValidYoutubeUrl(url)) {
    return null;
  }
  const type = getVideoTypeFromUrl(url);
  if (type === 'youtube') {
    return getYoutubeIdFromUrl(url);
  } else if (type === 'vimeo') {
    return getVimeoIdFromUrl(url);
  }
  return null;
};

export const getYoutubePreviewUrl = (videoId: string): string =>
  `https://img.youtube.com/vi/${videoId}/0.jpg`;

export const getVimeoEmbedUrl = (
  videoId: number,
  hash?: string | null,
): string =>
  `https://player.vimeo.com/video/${videoId}${hash ? `?h=${hash}` : ''}`;

export const getYoutubeEmbedUrl = (videoId: string): string =>
  `https://www.youtube-nocookie.com/embed/${videoId}`;
