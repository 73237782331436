/* eslint-disable menti-react/filename-convention--jsx */
import { Box, Form } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type {
  QuestionDimension,
  QuestionRange,
} from '@mentimeter/http-clients';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { type FormEvent, Fragment, useState } from 'react';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { LIKERT_SCALES } from './likertScales';
import { Option } from './Option';

const getLikert = (
  dimension: QuestionDimension,
  range: QuestionRange,
): string[] => {
  if (
    dimension.mid_values &&
    dimension.mid_values.length > 0 &&
    dimension.mid_values.length === range.max - range.min - 1
  ) {
    return [dimension.min, ...dimension.mid_values, dimension.max];
  }
  if (!dimension.min) return [];

  // Forms a string like 'min_label_max_label_nrOfLabels'
  const likertKey = `${dimension.min
    .toLowerCase()
    .split(' ')
    .join('_')}_${dimension.max.toLowerCase().split(' ').join('_')}_${
    range.max - range.min + 1
  }`;
  return LIKERT_SCALES[likertKey] ?? [];
};

function inRange(voteValue: number, range: QuestionRange) {
  return voteValue >= range.min && voteValue <= range.max;
}

interface Vote {
  [id: string]: { value: number; skipped: boolean };
}

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      description={question.question_description}
      title={question.question}
      titleStyled={question.question_styled}
      meta={question.title_meta}
      image={question.image}
    />
  );
}

export function Interactive() {
  const { useQuestion, useActions, useTheme, useTranslate } =
    useVotingContext();
  const { skip: skipVote, vote: submitVote } = useActions();
  const question = useQuestion();
  const theme = useTheme();
  const translate = useTranslate();

  const [values, setValues] = useState<Vote | null>(null);
  const [shouldShowConfirmSubmitModal, setShouldShowConfirmSubmitModal] =
    useState(false);

  const { choices, dimensions, range, hide_skip: hideSkip } = question;

  const [dimension] = dimensions;

  const likert = getLikert(dimension, range);

  // The validated vote that is updated to number of choices and within range
  const vote: Vote = choices.reduce((prev, c) => {
    const item = values && values[c.id];
    return {
      ...prev,
      [c.id]: {
        value:
          item?.value !== undefined && inRange(item.value, range)
            ? item.value
            : range.min,
        skipped: item?.skipped || false,
      },
    };
  }, {});

  function onChange(id: number, e: FormEvent<HTMLInputElement>) {
    const element = e.target as HTMLInputElement;
    const rawValue = element.value;

    if (rawValue.length === 0) return;
    const value = parseInt(rawValue, 10);
    if (!inRange(value, range)) return;
    setValues((state) => ({
      ...state,
      [id]: { value, skipped: false },
    }));
  }

  function onChangeOptionSkip(id: number) {
    const item = vote[id];
    if (!item) return;
    setValues((state) => ({
      ...state,
      [id]: {
        value: item.value,
        skipped: !item.skipped,
      },
    }));
  }

  function handleSubmit() {
    const actualVotes = choices.reduce((curr, { id }) => {
      const item = vote[id];
      const value = !item || item.skipped ? 'other' : [item.value, range.min];

      return {
        ...curr,
        [id]: value,
      };
    }, {});

    submitVote(actualVotes, false);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // Edge case: voting on a scales question without choices
    if (choices.length === 0) {
      skipVote();
      return;
    }

    if (values === null) {
      setShouldShowConfirmSubmitModal(true);
      return;
    }
    handleSubmit();
  }

  return (
    <Form onSubmit={onSubmit} width="100%">
      <Box width="100%" mb={3}>
        {choices.map((c, index) => (
          <Fragment key={c.id}>
            <Option
              index={index}
              id={c.id}
              value={vote[c.id]?.value}
              label={c.label}
              labels={dimensions}
              min={range.min}
              max={range.max}
              likert={likert}
              onChange={onChange}
              onChangeOptionSkip={onChangeOptionSkip}
              skippable={!hideSkip}
              skipped={Boolean(vote[c.id]?.skipped)}
              theme={theme}
            />
          </Fragment>
        ))}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="submit-vote-modal-scales"
        showModal={shouldShowConfirmSubmitModal}
        onConfirm={handleSubmit}
        onDismiss={() => setShouldShowConfirmSubmitModal(false)}
        title={translate('scales.confirmation_modal_title')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
