import React from 'react';
import { Box, CheckItem, Text } from '@mentimeter/ragnar-ui';
import { addUnit } from '@mentimeter/ragnar-utils';

interface MultipleChoiceCheckItemProps {
  id: string | number;
  selected: boolean;
  disabled?: boolean;
  hideCheckItem?: boolean;
  type: 'radio' | 'checkbox';
  name: string;
  children: React.ReactNode;
  value: string | number;
  onChange: (value: string) => void;
}

export const MultipleChoiceCheckItem = ({
  id,
  selected = false,
  disabled = false,
  hideCheckItem = false,
  type,
  name,
  value,
  onChange,
  children,
}: MultipleChoiceCheckItemProps) => {
  return (
    <Box
      flex="1 1 auto"
      py="space2"
      alignItems="center"
      flexDirection="row"
      gap="space5"
    >
      <Text
        color={selected ? 'onSecondaryWeakest' : 'text'}
        extend={({ theme }) => ({
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          gap: addUnit(theme.kosmosSpacing.space5),
        })}
      >
        {children}
      </Text>
      <Box
        ml="auto"
        extend={() => ({
          ...(hideCheckItem && {
            position: 'absolute',
            opacity: '0',
            height: '0',
            width: '0',
          }),
        })}
      >
        <CheckItem
          id={id.toString()}
          type={type}
          name={name}
          value={value}
          checked={selected}
          disabled={disabled}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        />
      </Box>
    </Box>
  );
};
