import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Player } from '@mentimeter/quiz';
import { Box } from '@mentimeter/ragnar-ui';
import { LoaderIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { FadeIn } from '../ui/Transitions';
import { AvatarWrapper } from '../ui/Avatar';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const NoLeaderboard = ({
  player,
  playerColor,
}: {
  player: Player | undefined;
  playerColor: string;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  return player ? (
    <>
      <AvatarWrapper player={player} playerColor={playerColor} />
      <Box mt="space4" />
      <FadeIn>
        <Box alignItems="center">
          <AnnouncedHeading fontSize="125" color="text" fontWeight="regular">
            {translate('state.have_not_voted')}
          </AnnouncedHeading>
        </Box>
      </FadeIn>
    </>
  ) : (
    <LoaderIcon />
  );
};
