import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box } from '@mentimeter/ragnar-ui';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { useCallback, useMemo, useState } from 'react';
import { RankingListWrapper } from './components/RankingListWrapper';
import type { RankableChoice } from './components/types';

export function Interactive() {
  const { useQuestion, useActions, useTranslate, gaTrackEvent } =
    useVotingContext();
  const { choices } = useQuestion();
  const [showModal, setShowModal] = useState(false);
  const { vote: submitVote, skip: skipVote } = useActions();
  const translate = useTranslate();

  const onError = useCallback(() => void setShowModal(true), []);

  const rankingChoices = useMemo<RankableChoice[]>(() => {
    return [...choices]
      .sort(() => 0.5 - Math.random())
      .map(({ id, label }) => ({
        id,
        label,
      }));
  }, [choices]);

  return (
    <Box width="100%">
      <RankingListWrapper
        rankableChoices={rankingChoices}
        submitVote={submitVote}
        onError={onError}
        trackEvent={gaTrackEvent}
        translate={translate}
      />

      <VotingConfirmationModal
        id="skip-vote-modal-ranking"
        showModal={showModal}
        onConfirm={skipVote}
        onDismiss={() => setShowModal(false)}
        title={translate('messages.not_made_choice')}
        bodyText={translate('messages.continue_without_voting')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Box>
  );
}
