export const emojiSvgPath =
  'https://static.mentimeter.com/static/svg/2.5/emoji-sprites.svg';

export const shortnameMap: { [shortname: string]: string } = {
  ':grey_question:': '2754',
  ':alien:': '1f47d',
  ':robot:': '1f916', // TODO: remove legacy from emojione
  ':robot_face:': '1f916',
  // ':dog:': '1f436',
  ':hamster:': '1f439',
  ':rabbit:': '1f430',
  ':bear:': '1f43b',
  ':koala:': '1f428',
  ':lion_face:': '1f981',
  ':frog:': '1f438',
  ':penguin:': '1f427',
  ':bird:': '1f426',
  ':wolf:': '1f43a',
  ':unicorn:': '1f984', // TODO: remove legacy from emojione
  ':unicorn_face:': '1f984',
  ':bee:': '1f41d',
  ':bug:': '1f41b',
  ':crab:': '1f980',
  ':tropical_fish:': '1f420',
  ':crocodile:': '1f40a',
  ':tiger2:': '1f405',
  ':cherry_blossom:': '1f338',
  ':rose:': '1f339',
  ':four_leaf_clover:': '1f340',
  ':smile_cat:': '1f638',
  ':soccer:': '26bd',
  ':football:': '1f3c8',
  ':8ball:': '1f3b1',
  ':space_invader:': '1f47e',
  ':articulated_lorry:': '1f69b',
  ':steam_locomotive:': '1f682',
  ':ship:': '1f6a2',
  ':spades:': '2660',
  ':volcano:': '1f30b',
  ':rowboat:': '1f6a3',
  ':taco:': '1f32e',
  ':hamburger:': '1f354',
  ':strawberry:': '1f353',
  ':snowflake:': '2744',
  ':fire:': '1f525',
  ':snowman:': '26c4', // TODO: remove legacy from emojione
  ':snowman_without_snow:': '26c4',
  ':crown:': '1f451',
  ':tophat:': '1f3a9',
  ':heart_eyes:': '1f60d',
  ':metal:': '1f918', // TODO: remove legacy from emojione
  ':the_horns:': '1f918',
  ':footprints:': '1f463',
  ':lollipop:': '1f36d',
  ':doughnut:': '1f369',
  ':heart_eyes_cat:': '1f63b',
  ':ring:': '1f48d',
  ':revolving_hearts:': '1f49e',
  ':paperclip:': '1f4ce',
  ':airplane_small:': '1f6e9', // TODO: remove legacy from emojione
  ':small_airplane:': '1f6e9',
  ':birthday:': '1f382',
  ':thinking:': '1f914', // TODO: remove legacy from emojione
  ':thinking_face:': '1f914',
  ':angel:': '1f47c',
  ':santa:': '1f385',
  ':spy:': '1f575', // TODO: remove legacy from emojione
  ':sleuth_or_spy:': '1f575',
  ':cop:': '1f46e',
  ':ghost:': '1f47b',
  ':banana:': '1f34c',
  ':watermelon:': '1f349',
  ':umbrella2:': '2602', // TODO: remove legacy from emojione
  ':umbrella:': '2602',
  ':dragon:': '1f409',
  ':blowfish:': '1f421',
  ':goat:': '1f410',
  ':flag_se:': '1f1f8-1f1ea', // TODO: remove legacy from emojione
  ':flag-se:': '1f1f8-1f1ea',
};

const quizSvgMapId = 'svg-quiz-emojis';
export const createQuizSvgMapInDOM = async () => {
  if (document.getElementById(quizSvgMapId)) return;
  try {
    const response = await fetch(emojiSvgPath);
    if (response.ok) {
      const content = await response.text();
      const emojiDiv = document.createElement('div');
      emojiDiv.id = 'svg-quiz-emojis';
      emojiDiv.style.display = 'none';
      emojiDiv.innerHTML = content;
      if (document.body) {
        document.body.appendChild(emojiDiv);
      }
    }
  } catch (error) {
    // TOOD: retry?
  }
};

export class Emoji {
  constructor() {
    this.create();
  }

  async create() {
    createQuizSvgMapInDOM();
  }

  getURL(shortname: keyof typeof shortnameMap) {
    const unicode = this.shortnameToUnicode(shortname);
    return `#emoji-${unicode}`;
  }

  shortnameToUnicode(shortname: keyof typeof shortnameMap) {
    return shortnameMap[shortname];
  }
}
