import { isAxiosError } from '@mentimeter/http-clients';

export const isRetriableNetworkError = (error: unknown) => {
  if (!isAxiosError(error)) return false;

  const NETWORK_ERROR_CODES_TO_RETRY = ['ETIMEDOUT', 'ERR_NETWORK'];
  if (!!error.code && NETWORK_ERROR_CODES_TO_RETRY.includes(error.code))
    return true;

  const STATUS_CODES_TO_RETRY = [408, 502, 503, 504];
  return (
    !!error.response?.status &&
    STATUS_CODES_TO_RETRY.includes(error.response.status)
  );
};

export const retry = async <D>(
  asyncFn: (tries: number) => Promise<D>,
  shouldRetry: (error: unknown) => boolean,
  maxTries = 3,
  tries = 1,
): Promise<D> => {
  try {
    return await asyncFn(tries);
  } catch (error) {
    if (tries >= maxTries) throw error;
    if (!shouldRetry(error)) throw error;

    return retry(asyncFn, shouldRetry, maxTries, tries + 1);
  }
};
