import React from 'react';
import { animated, easings, useTransition } from 'react-spring';

const FADE_IN_DELAY_FACTOR = 25;
const FADE_OUT_DELAY_FACTOR = 20;
export interface ExpandableListProps<T extends { id: string }> {
  show?: boolean;
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  flexDirection?: 'row' | 'column';
  animationDelay?: number;
  animationDurationIn?: number;
  animationDurationOut?: number;
}

export const ExpandableList = <T extends { id: string }>({
  show = true,
  items,
  renderItem,
  flexDirection = 'column',
  animationDelay = 200,
  animationDurationIn = 550,
  animationDurationOut = 150,
}: ExpandableListProps<T>) => {
  const transitions = useTransition(show ? items : [], {
    from: { opacity: 0, y: -20, height: 0 },
    enter: (_, i) => (next) =>
      next({
        opacity: 1,
        y: 0,
        height: 'auto',
        delay: animationDelay + i * FADE_IN_DELAY_FACTOR,
      }),
    leave: (_, i) => (next) =>
      next({
        opacity: 0,
        y: -10,
        height: 0,
        delay: (items.length - i + 10) * FADE_OUT_DELAY_FACTOR,
      }),
    config: {
      duration: show ? animationDurationIn : animationDurationOut,
      easing: easings.easeOutBack,
    },
  });

  return (
    <ul
      style={{
        listStyleType: 'none',
        flexDirection,
        display: 'flex',
        width: '100%',
      }}
    >
      {transitions((style, item, _, i) => {
        return item ? (
          <animated.li key={i} style={style}>
            {renderItem(item)}
          </animated.li>
        ) : (
          ''
        );
      })}
    </ul>
  );
};
