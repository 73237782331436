import React, { useCallback, type FC } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { ResponseCluster } from '@mentimeter/http-clients';
import { getGroupsShapes } from '@mentimeter/slides-presentation-ui/groups/group-shapes';
import { UNGROUPED_CLUSTER_ID } from '../../ungroupedClusterId';
import type { OpenVotingResultsT } from '../types';
import type { Upvotes, useUpvotes } from './useUpvotes';
import { UpvotingView, type Answers } from './UpvotingView';

interface UpvotingViewDataHandlerProps {
  publicKey: string;
  maxVotes: number;
  multipleUpvotesEnabled: boolean;
  isGrouped: boolean;
  upvoting: ReturnType<typeof useUpvotes>;
}
export const UpvotingViewDataHandler: FC<UpvotingViewDataHandlerProps> = ({
  publicKey,
  maxVotes,
  multipleUpvotesEnabled,
  isGrouped,
  upvoting,
}) => {
  const { useResults, useActions, useGroupedResults, useTheme, useTranslate } =
    useVotingContext();
  const { submitUpvote } = useActions();
  const { fillColors } = useTheme();
  const translate = useTranslate();

  const submitUpvotes = useCallback(
    async (upvotes: Upvotes) => {
      const promises = Object.entries(upvotes).flatMap(([voteId, count]) => {
        // Array(count).fill(submitUpvote(voteId)) didn't work for some reason
        // creates an array of count 0s and then maps over it to submit upvote promise
        return [...Array(count).fill(0)].map(() => submitUpvote(voteId));
      });
      await Promise.all(promises);
    },
    [submitUpvote],
  );

  const { votes: responses = [] } =
    useResults<OpenVotingResultsT>(publicKey, !isGrouped).data ?? [];

  const groupedResults = useGroupedResults(publicKey, isGrouped).data;

  const ungroupedResponsesAsGroup: ResponseCluster = {
    id: UNGROUPED_CLUSTER_ID,
    label: translate('open_ended.new_responses'),
    responses: groupedResults?.unclustered_responses ?? [],
    clustering_id: UNGROUPED_CLUSTER_ID,
    total_upvotes: 0,
  };
  const isUngroupedResponsesEmpty =
    ungroupedResponsesAsGroup.responses.length === 0;

  const groupedResponses = groupedResults?.clusters ?? [];
  const groups = isUngroupedResponsesEmpty
    ? groupedResponses
    : [...groupedResponses, ungroupedResponsesAsGroup];

  const shapes = getGroupsShapes(groups, fillColors, UNGROUPED_CLUSTER_ID);
  const groupsWithShapes = groups.map((group) => ({
    ...group,
    shape: shapes.get(group.id),
  }));

  const answers: Answers = isGrouped
    ? {
        type: 'groups',
        groups: groupsWithShapes,
      }
    : {
        type: 'responses',
        responses,
      };

  return (
    <UpvotingView
      multipleUpvotesEnabled={multipleUpvotesEnabled}
      maxVotes={maxVotes}
      responses={answers}
      submitUpvotes={submitUpvotes}
      upvoting={upvoting}
    />
  );
};
