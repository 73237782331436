import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { RankableChoice } from './types';
interface SortableDropItemT {
  choice: RankableChoice;
  index: number;
}

export const SortableDropItem = ({ choice, index }: SortableDropItemT) => {
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id: choice.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
  };

  return (
    <Box
      bg={isDragging ? 'primaryWeakest' : 'bg'}
      flex="1 1 auto"
      width="100%"
      minHeight="5rem"
      alignItems="center"
      flexDirection="row"
      ref={setNodeRef}
      borderRadius="4xl"
      style={style}
      aria-hidden
      {...listeners}
      extend={({ theme }) => ({
        outline: isDragging
          ? `4px solid ${theme.colors.interactiveFocused}`
          : 'none',
        outlineOffset: '2px',
        '&:focus div:nth-child(2)': {
          paddingBottom: '2px',
          borderBottomWidth: 0,
        },
        '&:focus': {
          outline: `4px solid ${theme.colors.interactiveFocused}`,
        },
      })}
    >
      <Box minWidth="30px" style={{ opacity: isDragging ? 0 : 1 }} ml="space1">
        <Text
          fontSize="125"
          color="text"
          ml="space1"
          style={{ userSelect: 'none' }}
        >
          {index >= 1 ? index + 1 : 1}
        </Text>
      </Box>
      <Box
        flex="1 1 auto"
        borderColor="border"
        borderBottomWidth={isDragging ? 0 : 1}
        borderStyle="solid"
        justifyContent="space-between"
        minHeight="inherit"
        alignItems="center"
        flexDirection="row"
      >
        <Box flex="1 1 auto">
          <Text
            color="text"
            ml="space2"
            textAlign="left"
            py="space4"
            style={{ opacity: isDragging ? 0 : 1, userSelect: 'none' }}
          >
            {choice.label}
          </Text>
        </Box>
        <Box flexDirection="row" width="96px" height="48px" />
      </Box>
    </Box>
  );
};
