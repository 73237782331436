import { darkPalette, whiteBase } from '@mentimeter/ragnar-colors';
import { Icon, type IconT } from '@mentimeter/ragnar-visuals';

export function PinIcon(props: IconT) {
  return (
    <Icon viewBox="0 0 34 34" {...props}>
      <path
        d="M6 13.5321C6 7.71538 10.7844 3 16.6863 3H17.3137C23.2156 3 28 7.71538 28 13.5321C28 16.4765 26.7494 19.2865 24.5514 21.2808L17.2528 27.9028C17.1099 28.0324 16.8901 28.0324 16.7472 27.9028L9.44858 21.2808C7.2506 19.2865 6 16.4765 6 13.5321Z"
        fill={darkPalette.gray1100}
      />
      <path
        d="M17.3137 2H16.6863C10.2459 2 5 7.14945 5 13.5321C5 16.7612 6.37164 19.8393 8.77664 22.0214L16.0752 28.6434C16.5993 29.1189 17.4007 29.1189 17.9248 28.6434L25.2234 22.0214C27.6284 19.8393 29 16.7612 29 13.5321C29 7.14945 23.7541 2 17.3137 2Z"
        stroke={whiteBase}
        strokeWidth="2"
      />
    </Icon>
  );
}
