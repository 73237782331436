import type { CommandProps } from '@tiptap/core';
import Heading from '@tiptap/extension-heading';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    setHeadingWithClass: {
      /**
       * Set heading with css class
       * @example editor.commands.setHeadingWithClass({level, class})
       */
      setHeadingWithClass: (attributes: {
        level: number;
        class?: string | undefined;
      }) => ReturnType;
    };
  }
}

export const ExtendedHeading = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      // Set the default level to 2 (h2)
      level: {
        default: 2,
      },
      class: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes?.class) {
            return null;
          }
          return {
            class: `${attributes.class}`,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setHeadingWithClass:
        (attributes: Record<string, any>) =>
        ({ commands }: CommandProps) => {
          return commands.setNode('heading', attributes);
        },
    };
  },
});
