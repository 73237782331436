import React from 'react';
import { Ratio } from '@mentimeter/ragnar-ui';
import {
  getVideoTypeFromUrl,
  getVimeoEmbedUrl,
  getVimeoHashFromUrl,
  getVimeoIdFromUrl,
  getYoutubeEmbedUrl,
  getYoutubeIdFromUrl,
} from './utils';

export const VideoPlayer = ({ videoUrl }: { videoUrl: string }) => {
  const type = getVideoTypeFromUrl(videoUrl);

  if (type === 'youtube') {
    const videoId = getYoutubeIdFromUrl(videoUrl);
    if (!videoId) return null;

    const videoSrc = getYoutubeEmbedUrl(videoId);
    return (
      <Ratio ratio="landscape">
        <iframe
          className="aspect-ratio--object"
          width="100%"
          height="100%"
          src={videoSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Ratio>
    );
  }

  if (type === 'vimeo') {
    const videoId = getVimeoIdFromUrl(videoUrl);
    const videoHash = getVimeoHashFromUrl(videoUrl);
    if (!videoId) return null;
    const embedUrl = getVimeoEmbedUrl(videoId, videoHash);
    return (
      <Ratio ratio="landscape">
        <iframe
          className="aspect-ratio--object"
          src={embedUrl}
          width="100%"
          height="100%"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </Ratio>
    );
  }

  return null;
};
