import React, { useState } from 'react';
import {
  type DragEndEvent,
  type DragStartEvent,
  type DragOverEvent,
  type DropAnimation,
  TouchSensor,
  MouseSensor,
} from '@dnd-kit/core';
import {
  useSensors,
  useSensor,
  DndContext,
  closestCorners,
  DragOverlay,
  defaultDropAnimation,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useRagnar } from '@mentimeter/ragnar-react';
import { alpha } from '@mentimeter/ragnar-colors';
import type { VotingSDKT } from '@mentimeter/question-modules-types';
import type { DropSectionsT, RankableChoice } from './types';
import { getDropSectionContainers, getChoiceById } from './utils/dropFunctions';
import { RankedDropSection } from './RankedDropSection';
import { DropItem } from './DropItem';
import { UnrankedDropSection } from './UnrankedDropSection';

interface RankingListContextT {
  dropSections: DropSectionsT;
  setDropSections: React.Dispatch<React.SetStateAction<DropSectionsT>>;
  trackEvent: VotingSDKT['gaTrackEvent'];
  translate: ReturnType<VotingSDKT['useTranslate']>;
}

export const RankingContext = ({
  dropSections,
  setDropSections,
  trackEvent,
  translate,
}: RankingListContextT) => {
  const [activeChoiceId, setActiveChoiceId] = useState<null | number>(null);
  const [sortWithClick, setSortWithClick] = React.useState(false);
  const [uiState, setUiState] = React.useState({
    isDragging: false,
    isOverRankedContainer: false,
  });

  const { theme } = useRagnar();
  const totalChoices = [
    ...(dropSections.choices ?? []),
    ...(dropSections.rankedChoices ?? []),
  ];

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
  );
  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveChoiceId(active.id as number);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    if (!active || !over || over.id === active.id) return;
    const { overContainer, activeContainer } = getDropSectionContainers(
      dropSections,
      active.id as number,
      over.id as number,
    );
    setUiState({
      ...uiState,
      isOverRankedContainer: overContainer === 'rankedChoices',
      isDragging: true,
    });
    if (activeContainer === overContainer) return;

    const overItems = [...(dropSections[overContainer] ?? [])];
    const activeItems = [...(dropSections[activeContainer] ?? [])];
    const activeIndex = activeItems?.findIndex((item) => item.id === active.id);
    const newActiveItems = activeItems?.filter((item) => item.id !== active.id);

    setDropSections((dropSections) => {
      const newOverItems = () => {
        const activeItem = activeItems[activeIndex];
        if (activeItem) {
          overItems.splice(overItems.length, 0, activeItem);
        }
        return overItems;
      };
      return {
        ...dropSections,
        [activeContainer]: newActiveItems,
        [overContainer]: newOverItems(),
      };
    });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setUiState({
      ...uiState,
      isDragging: false,
      isOverRankedContainer: false,
    });
    if (!active || !over) return;
    const { overContainer, activeContainer } = getDropSectionContainers(
      dropSections,
      active.id as number,
      over.id as number,
    );
    if (
      activeContainer !== overContainer ||
      (activeContainer === 'choices' && activeContainer === overContainer)
    ) {
      return;
    }
    const activeIndex = dropSections[activeContainer]?.findIndex(
      (item) => item.id === active.id,
    );
    const overIndex = dropSections[overContainer]?.findIndex(
      (item) => item.id === over?.id,
    );

    if (activeIndex !== overIndex) {
      setDropSections((dropSection) => ({
        ...dropSection,
        [overContainer]: arrayMove(
          dropSection[overContainer] ?? [],
          activeIndex as number,
          overIndex as number,
        ),
      }));
      trackEvent('event status', 'Ranking reordered item');
    }
    setActiveChoiceId(null);
  };

  const sendToRanked = (item: RankableChoice) => {
    const newUnrankedList =
      dropSections.choices?.filter((choice) => choice.id !== item.id) ?? [];

    const newRankedList = dropSections.rankedChoices ?? [];
    setDropSections({
      rankedChoices: [...newRankedList, item],
      choices: newUnrankedList,
    });
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };
  const choice = activeChoiceId
    ? getChoiceById(totalChoices, activeChoiceId)
    : null;

  const handleMoveIndex = (currentIndex: number, newIndex: number) => {
    setSortWithClick(true);
    setDropSections((dropSection) => ({
      ...dropSection,
      rankedChoices: arrayMove(
        dropSections.rankedChoices ?? [],
        currentIndex,
        newIndex,
      ),
    }));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <RankedDropSection
        id="rankedChoices"
        rankedChoices={dropSections.rankedChoices ?? []}
        isOverDropzone={uiState.isOverRankedContainer}
        isDragging={uiState.isDragging}
        onMoveIndex={handleMoveIndex}
        sortWithClick={sortWithClick}
        setSortWithClick={setSortWithClick}
      />

      {dropSections.choices?.length ? (
        <UnrankedDropSection
          id="unrankedChoices"
          choices={dropSections.choices}
          sendToRanked={sendToRanked}
          translate={translate}
        />
      ) : null}

      {choice ? (
        <DragOverlay
          dropAnimation={dropAnimation}
          style={{
            boxShadow: `0 4px 12px ${alpha(theme.colors.bgOverlay, 0.5)}`,
            borderRadius: `${theme.kosmosBorderRadius['4xl']}px`,
          }}
        >
          <DropItem choice={choice} isDragging={uiState.isDragging} />
        </DragOverlay>
      ) : null}
    </DndContext>
  );
};
