export const getOrdinal = (
  position: number,
  translate: (key: string) => string,
) => {
  const s = [
    translate('state.th'),
    translate('state.st'),
    translate('state.nd'),
    translate('state.rd'),
  ];
  const v = position % 100;
  return position + (s[(v - 20) % 10] ?? s[v] ?? s[0] ?? '');
};
