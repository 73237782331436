import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  TitleSectionContainer,
  VotingMetaText,
  VotingTitle,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const question = useQuestion();
  const translate = useTranslate();

  return (
    <TitleSectionContainer>
      <VotingMetaText meta={question.title_meta} mb="space2" />
      <VotingTitle title={question.question} mb="space6" />

      <QuestionImage translateFn={translate} src={question.image} />
    </TitleSectionContainer>
  );
}
