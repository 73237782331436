import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box, Image, Text } from '@mentimeter/ragnar-ui';
import { useTimeout } from '@mentimeter/react-hooks';
import * as React from 'react';
import { ProgressBar } from '../ui/ProgressBar';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';
import { TitleSectionWithImage } from '../../VotingTitle';
import { FadeIn } from '../ui/Transitions';
import { SecondsCountdown } from '../ui/SecondsCountdown';

export const Countdown = ({
  children,
  question,
  questionStyled,
  description,
  playerColor,
  timeBasedScoring,
  imageUrl,
  timeOffset,
  startAt,
  endAt,
}: React.PropsWithChildren<{
  question: string;
  questionStyled?: Record<string, unknown> | undefined;
  description: string;
  playerColor: string;
  timeBasedScoring: boolean;
  imageUrl: string | undefined;
  timeOffset: number;
  startAt: number;
  endAt: number;
}>) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const [state, setState] = React.useState<'countdown' | 'start' | 'end'>(
    'countdown',
  );

  const diffFromNow = Date.now() + timeOffset;
  useTimeout(() => setState('start'), startAt - diffFromNow);
  useTimeout(() => setState('end'), endAt - diffFromNow);

  return (
    <Box alignItems="stretch" width="100%">
      <FadeIn>
        <Box mb="space8">
          <AnnouncedHeading color="text" fontSize="175" fontWeight="regular">
            <TitleSectionWithImage
              title={question}
              titleStyled={questionStyled}
              description={description}
            />
          </AnnouncedHeading>
        </Box>
        <Box alignItems="center">
          {imageUrl && (
            <Box my="space2" width="100%" maxHeight={400} maxWidth={400}>
              <Image
                src={imageUrl}
                extend={({ theme }) => ({
                  borderRadius: `${theme.radii[1]}px`,
                })}
              />
            </Box>
          )}
        </Box>
      </FadeIn>
      {state === 'countdown' ? (
        <FadeIn>
          <Box>
            <SecondsCountdown timeRemaining={startAt - diffFromNow}>
              {(seconds) => (
                <Text color="text" fontWeight="semiBold" fontSize="600">
                  {seconds.toString()}
                </Text>
              )}
            </SecondsCountdown>
          </Box>
        </FadeIn>
      ) : state === 'start' ? (
        <>
          <Box my="space4">
            <ProgressBar
              startAt={startAt}
              endAt={endAt}
              isTimeBasedScoring={timeBasedScoring}
            />
          </Box>
          {children}
        </>
      ) : (
        <Text fontSize="175">{translate('message.loading')}</Text>
      )}
    </Box>
  );
};
