/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import {
  TitleSectionContainer,
  VotingDescription,
} from '../../ui/Components/VotingTitle';
import { QuestionImage } from '../../ui/Components/QuestionImage';

export function Content() {
  const { useQuestion, useTranslate } = useVotingContext();
  const { image, question_description_styled, question_description } =
    useQuestion();
  const translate = useTranslate();

  return (
    <TitleSectionContainer>
      <QuestionImage src={image} translateFn={translate} />
      <VotingDescription
        description={question_description}
        descriptionStyled={question_description_styled}
      />
    </TitleSectionContainer>
  );
}
