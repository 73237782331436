import * as React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { ClockIcon, ClockOffIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '@mentimeter/ragnar-ui';
import { FadeIn, ScaleIn } from '../ui/Transitions';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const GetReady = ({
  timeBasedScoring,
}: {
  timeBasedScoring: boolean;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const Icon = timeBasedScoring ? ClockIcon : ClockOffIcon;

  return (
    <>
      <ScaleIn>
        {/* Will be replaced by new graphic */}
        <Box height="180px" justifyContent="center">
          <Icon size={8} color="primary" />
        </Box>
      </ScaleIn>
      <FadeIn>
        <Box alignItems="center">
          <AnnouncedHeading
            fontWeight="regular"
            fontSize="175"
            mt="space14"
            mb="space6"
            color="text"
            textAlign="center"
          >
            {translate(
              timeBasedScoring
                ? 'state.answer_fast'
                : 'state.max_points_correct_answer',
            )}
          </AnnouncedHeading>
        </Box>
      </FadeIn>
    </>
  );
};
