/* eslint-disable menti-react/filename-convention--jsx */
import { useMemo, useState } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box, Form } from '@mentimeter/ragnar-ui';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { VoteOption } from './VoteOption';

const POOL = 100;
const STEP = 10;

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      image={question.image}
      meta={question.title_meta}
      title={question.question}
      titleStyled={question.question_styled}
      description={question.question_description}
    />
  );
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote } = useActions();
  const question = useQuestion();
  const translate = useTranslate();

  const [showModal, setShowModal] = useState(false);
  const [values, setValues] = useState(initializeValues);

  const pointsLeft = useMemo(() => {
    return Object.keys(values).reduce((mem, id) => {
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return mem - values[parseInt(id, 10)];
    }, POOL);
  }, [values]);

  function initializeValues() {
    return question.choices.reduce((hash, choice) => {
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      hash[choice.id] = 0;
      return hash;
    }, {});
  }

  function onChange(id: number, value: number) {
    setValues({ ...values, [id]: value });
  }

  function onSubmitVote() {
    if (showModal) {
      setShowModal(false);
    }
    submitVote(values, false);
  }

  return (
    <Box width="100%" alignItems="stretch">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (showModal === false && pointsLeft > 0) {
            setShowModal(true);
          } else {
            onSubmitVote();
          }
        }}
        width="100%"
      >
        <Box
          width="100%"
          alignItems="stretch"
          gap="space14"
          pt="space2"
          pb="space6"
        >
          {question.choices.map((choice) => (
            <VoteOption
              key={choice.id}
              id={choice.id}
              title={choice.label}
              // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              value={values[choice.id]}
              onChange={onChange}
              pointsLeft={pointsLeft}
              pool={POOL}
              step={STEP}
              translate={translate}
            />
          ))}
        </Box>
        <SubmitVoteFormButton />
      </Form>
      <VotingConfirmationModal
        id="skip-vote-modal-100-points"
        showModal={showModal}
        onConfirm={onSubmitVote}
        onDismiss={() => setShowModal(false)}
        title={translate('prioritisation.not_100_points')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Box>
  );
}
