import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { VisualizationColors } from '@mentimeter/ragnar-colors';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import type { QuestionDimension } from '@mentimeter/http-clients';
import { Row } from './Row';

interface PropsT {
  index: number;
  id: number;
  value: { x: number; y: number };
  subType?: string | null;
  labels: [QuestionDimension, QuestionDimension];
  label: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (arg0: number, arg1: string) => void;
  onSkip: (arg0: number) => void;
  skippable: boolean;
  skipped: boolean;
  theme: VisualizationColors;
}

export function Option({
  index,
  id,
  label,
  value,
  subType,
  min = 0,
  max = 5,
  step = 1,
  labels,
  onChange,
  onSkip,
  skippable,
  skipped,
  theme,
}: PropsT) {
  const {
    ThemeStyled: { StringParser },
    useTranslate,
  } = useVotingContext();
  const translate = useTranslate();
  const allowNegative = subType !== 'four-values';

  return (
    <Box
      as="fieldset"
      aria-disabled={skipped}
      width="100%"
      alignItems="stretch"
      mt={index !== 0 ? 'space14' : 'space0'}
    >
      {/* Label */}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="space3"
      >
        <Text
          as="legend"
          lineHeight="heading"
          fontWeight="semiBold"
          color="text"
          fontSize="112.5"
        >
          <StringParser source={label} disableLineBreaks />
        </Text>

        {skippable && (
          <Button
            size="compact"
            aria-label={
              skipped
                ? `${translate('buttons.unskip')} ${label}`
                : `${translate('buttons.skip')} ${label}`
            }
            aria-disabled={false}
            variant="tertiary"
            onClick={() => onSkip(id)}
            ml="space4"
          >
            {skipped ? translate('buttons.unskip') : translate('buttons.skip')}
          </Button>
        )}
      </Box>

      <Box
        width="100%"
        alignItems="stretch"
        extend={({ theme: t }) => ({
          opacity: skipped ? t.opacity.faded : 1,
        })}
      >
        <Row
          // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
          label={labels[0].label}
          labelMin={labels[0].min}
          labelMax={labels[0].max}
          allowNegative={allowNegative}
          index={index}
          id={id}
          min={min}
          max={max}
          step={step}
          value={value && value.x}
          onChange={onChange}
          axis="x"
          skipped={skipped}
          theme={theme}
        />

        {/* Y axis */}
        <Row
          // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
          label={labels[1].label}
          labelMin={labels[1].min}
          labelMax={labels[1].max}
          allowNegative={allowNegative}
          index={index}
          id={id}
          min={min}
          max={max}
          step={step}
          value={value && value.y}
          onChange={onChange}
          skipped={skipped}
          axis="y"
          theme={theme}
        />
      </Box>
    </Box>
  );
}
