import React from 'react';
import { Box, ScreenReaderOnly, Text } from '@mentimeter/ragnar-ui';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { isTouchDevice } from '@mentimeter/ragnar-device';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { RankableChoice } from './types';
import { SortableDropItem } from './SortableDropItem';
import { SortButtons } from './SortButtons';
interface RankedDropSectionT {
  id: string;
  rankedChoices: RankableChoice[] | undefined;
  isOverDropzone: boolean;
  isDragging: boolean;
  onMoveIndex: (currentIndex: number, newIndex: number) => void;
  sortWithClick: boolean;
  setSortWithClick: (moveIndex: boolean) => void;
}

export const RankedDropSection = ({
  id,
  isDragging,
  rankedChoices = [],
  isOverDropzone,
  sortWithClick,
  onMoveIndex,
  setSortWithClick,
}: RankedDropSectionT) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();
  const isTouch = isTouchDevice();
  const isEmptyRanking = !rankedChoices?.length && id === 'rankedChoices';
  const showFocused = !isOverDropzone && isDragging;

  return (
    <Box
      width="100%"
      extend={() => ({
        '& .m-flipper': {
          width: '100%',
        },
      })}
    >
      <Flipper flipKey={sortWithClick} className="m-flipper">
        <Text as="h2" color="text" mb="space4" lineHeight="150">
          {translate('ranking.header_ranked')}
        </Text>
        <SortableContext
          id={id}
          items={rankedChoices}
          strategy={verticalListSortingStrategy}
        >
          <Box
            width="100%"
            height={isEmptyRanking ? '5rem' : 'auto'}
            mb="space10"
            pb="space4"
            bg={isEmptyRanking && !isOverDropzone ? 'surfaceSunken' : 'bg'}
            borderRadius="4xl"
            alignItems="center"
            justifyContent="center"
            extend={({ theme }) => ({
              outline: showFocused
                ? `4px solid ${theme.colors.interactiveFocused}`
                : isEmptyRanking && !isOverDropzone
                  ? `1px dashed ${theme.colors.borderStrong}`
                  : '',
              outlineOffset: showFocused ? '2px' : 0,
            })}
          >
            {isEmptyRanking && (
              <>
                <Text color="textWeaker" pt="space4">
                  {isTouch
                    ? translate('ranking.dropzone_tap')
                    : translate('ranking.dropzone_click')}
                </Text>

                <ScreenReaderOnly>
                  {translate('ranking.screenreader_rank_explainer')}
                </ScreenReaderOnly>
              </>
            )}

            {rankedChoices && (
              <Box
                ref={setNodeRef}
                width="100%"
                as="ol"
                data-testid="ranked-list"
                gap="space2"
              >
                {rankedChoices.map(
                  (item, index) =>
                    item && (
                      <Flipped key={`element-${index}`} flipId={item.id}>
                        <Box
                          key={item.id}
                          flexDirection="row"
                          alignItems="center"
                          width="100%"
                          justifyContent="space-between"
                          aria-label={`${index + 1}, ${item.label}`}
                          as="li"
                        >
                          <SortableDropItem
                            key={item.id}
                            choice={item}
                            index={index}
                          />
                          <SortButtons
                            index={index}
                            onMoveIndex={onMoveIndex}
                            sortWithClick={sortWithClick}
                            setSortWithClick={setSortWithClick}
                            rankedChoices={rankedChoices}
                            isDragging={isDragging}
                            item={item}
                            translate={translate}
                          />
                        </Box>
                      </Flipped>
                    ),
                )}
              </Box>
            )}
          </Box>
        </SortableContext>
      </Flipper>
    </Box>
  );
};
