import { MixShape, allShapes, ArcUpShape } from './Shapes';

export type GroupShapeMap = Map<string, React.ReactNode>;

// Warning: this function depends on the total size of the groups array
// always use it on all of the groups, not just a subset
export const getGroupsShapes = <T extends { id: string }>(
  groups: T[],
  fillColors: string[],
  ungroupedGroupId: string | null,
): GroupShapeMap => {
  // Sorting by id to ensure shapes are assigned to the same group always
  const sortedGroups = [...groups].sort((a, b) => a.id.localeCompare(b.id));

  const shapes = new Map<string, React.ReactNode>(
    sortedGroups.map(({ id: groupId }, index: number) => {
      const shapeIndex = index % allShapes.length;
      const colorIndex = index % fillColors.length;

      const component = allShapes[shapeIndex] ?? ArcUpShape;

      return [groupId, component({ color: fillColors[colorIndex] })];
    }),
  );

  if (ungroupedGroupId) {
    shapes.set(ungroupedGroupId, MixShape({ fillColors }));
  }

  return shapes;
};
