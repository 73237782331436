import React from 'react';
import { voting } from '@mentimeter/http-clients';
import { addBreadcrumb } from '@mentimeter/errors/sentry';
import { useQuizStatus } from './use-quiz-status';
import { useEmit } from './use-emit';
import type { Player, VoterConfig } from './types';
import { ClientErrorCodes, ErrorCodes, GameStateEnums } from './types';
import { useSocketIoConnection } from './connection-provider';
import { type GameStates, VoterEvents } from './events';
import { useSubscribe } from './use-subscribe';
import { isRetriableNetworkError, retry } from './retry';

const DEFAULT_PLAYER_COLOR = 'black';
const JOIN_QUIZ_AUTOMATIC_THRESHOLD = 500;

const useGameState = (type: string, publicKey: string) => {
  const [gameState, setCurrentGameState] = React.useState<GameStates>();

  // Debugging weird game state data for countdown
  const prevState = React.useRef<GameStates | undefined>(undefined);
  const setGameState = React.useCallback(
    (
      nextGameState: React.SetStateAction<GameStates | undefined>,
      source: string,
    ) => {
      const gameStateForDebugging =
        typeof nextGameState === 'function'
          ? nextGameState(prevState.current)
          : nextGameState;

      // Update actual state
      setCurrentGameState(nextGameState);

      const breadcrumbData: Record<string, any> = {
        // The from value is not always correct, since multiple setGameState can happen almost at the same time
        from: prevState.current?.gameState ?? 'undefined',
        to: gameStateForDebugging?.gameState ?? 'undefined',
        source,
        publicKey,
      };

      if (gameStateForDebugging?.gameState === GameStateEnums.COUNTDOWN) {
        breadcrumbData['gameStateData'] = gameStateForDebugging.gameStateData;
        breadcrumbData['errorCode'] = gameStateForDebugging.errorCode;
      }

      addBreadcrumb({
        category: type,
        message: 'Game state changed',
        data: breadcrumbData,
      });

      prevState.current = gameStateForDebugging;
    },
    [publicKey, type],
  );

  return [gameState, setGameState] as const;
};

export const useQuizVoterGameState = (
  config: VoterConfig,
  type: 'quiz-choices' | 'quiz-open' | 'quiz-leaderboard',
) => {
  const { emit } = useEmit(config);
  const { connection } = useSocketIoConnection();
  const { connected, timeOffset } = useQuizStatus(connection);

  const localState = React.useRef<'idle' | 'joined'>('idle');
  const [player, setPlayer] = React.useState<Player>();

  const [gameState, setGameState] = useGameState(
    type,
    config.questionPublicKey,
  );

  const playerRef = React.useRef<Player | undefined>(undefined);
  React.useEffect(() => {
    playerRef.current = player;
  }, [player]);

  const setClientError = React.useCallback(
    (errorCode: ClientErrorCodes, source: string) => {
      setGameState((prev) => {
        if (!prev)
          return {
            gameState: GameStateEnums.ERROR,
            gameStateData: {},
            errorCode,
          };
        return { ...prev, errorCode };
      }, source);
    },
    [setGameState],
  );

  const _requestPlayer = React.useCallback(async (): Promise<Player> => {
    if (playerRef.current) return Promise.resolve(playerRef.current);
    const { data } = await retry(
      (tries) =>
        voting().quiz.fetchPlayer(config.voteKey, config.identifier, tries),
      isRetriableNetworkError,
      10,
    );
    return data;
  }, [config.voteKey, config.identifier]);

  const _emitJoin = React.useCallback(
    async (player: Player) => {
      const response = await emit(VoterEvents.PlayerJoin, { player });
      if (response.errorCode) {
        localState.current = 'idle';
      }
      return response;
    },
    [emit],
  );

  const updatePlayer = React.useCallback(
    async (name: string) => {
      const { data: player } = await voting().quiz.updatePlayer(
        config.voteKey,
        {
          name,
        },
      );
      setPlayer(player);
      _emitJoin(player);
      return player;
    },
    [_emitJoin, config.voteKey],
  );

  const joinQuiz = React.useCallback(async () => {
    if (localState.current !== 'idle') return;
    localState.current = 'joined';

    try {
      const player = await _requestPlayer();
      const response = await _emitJoin(player);
      setPlayer(player);
      setGameState(response, 'joinQuiz');
    } catch (error: any) {
      localState.current = 'idle';
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data?.code === 'quiz_limit_reached'
      ) {
        return setClientError(ClientErrorCodes.TOO_MANY_PLAYERS, 'joinQuiz');
      }
      // NOOP as we get server errors in the response
    }
  }, [_emitJoin, _requestPlayer, setClientError, setGameState]);

  const submitChoiceVote = React.useCallback(
    async (choiceId: number) => {
      if (gameState?.gameState !== GameStateEnums.COUNTDOWN)
        throw new Error('Wrong gamestate to vote');

      try {
        const response = await emit(VoterEvents.PlayerVote, {
          voteTimestamp: Date.now() + (timeOffset ?? 0),
          vote: [choiceId],
          clientTimestamps: {
            startAt: gameState.gameStateData.startAt,
            endAt: gameState.gameStateData.endAt,
          },
        });
        setGameState(response, 'submitChoiceVote');
      } catch (error) {
        return setClientError(
          ClientErrorCodes.VOTE_FAILURE,
          'submitChoiceVote',
        );
      }
    },
    [emit, gameState, setClientError, setGameState, timeOffset],
  );

  const submitOpenAnswer = React.useCallback(
    async (answer: string) => {
      if (gameState?.gameState !== GameStateEnums.COUNTDOWN)
        throw new Error('Wrong gamestate to vote');
      try {
        const response = await emit(VoterEvents.PlayerVote, {
          voteTimestamp: Date.now() + (timeOffset ?? 0),
          vote: [answer],
          clientTimestamps: {
            startAt: gameState.gameStateData.startAt,
            endAt: gameState.gameStateData.endAt,
          },
        });
        setGameState(response, 'submitOpenAnswer');
      } catch (error) {
        return setClientError(
          ClientErrorCodes.VOTE_FAILURE,
          'submitOpenAnswer',
        );
      }
    },
    [emit, gameState, setClientError, setGameState, timeOffset],
  );

  const getPlayerColor = (colors: string[]): string => {
    return colors[(player?.index ?? 0) % colors.length] || DEFAULT_PLAYER_COLOR;
  };

  // Listeners

  const isCurrentQuestion = React.useCallback(
    ({ questionPublicKey }: { questionPublicKey: string }) =>
      questionPublicKey === config.questionPublicKey,
    [config.questionPublicKey],
  );

  // General quiz flow
  useSubscribe({
    event: 'quiz_created',
    callback: React.useCallback(
      (gameState) => {
        if (!isCurrentQuestion(gameState.gameStateData)) return;
        localState.current = 'idle';
        setGameState(undefined, 'quiz_created');
        joinQuiz();
      },
      [isCurrentQuestion, joinQuiz, setGameState],
    ),
  });
  useSubscribe({
    event: 'quiz_started',
    callback: React.useCallback(
      (gameState) => {
        if (!isCurrentQuestion(gameState.gameStateData)) return;
        setGameState(gameState, 'quiz_started');
      },
      [isCurrentQuestion, setGameState],
    ),
  });
  useSubscribe({
    event: 'quiz_countdown_started',
    callback: React.useCallback(
      (gameState) => {
        if (!isCurrentQuestion(gameState.gameStateData)) return;
        setGameState(gameState, 'quiz_countdown_started');
      },
      [isCurrentQuestion, setGameState],
    ),
  });
  useSubscribe({
    event: 'quiz_ended',
    callback: React.useCallback(
      async (gameState) => {
        if (!isCurrentQuestion(gameState.gameStateData)) return;
        try {
          const response = await emit(VoterEvents.PlayerResults, undefined);
          if (
            response.errorCode === ErrorCodes.FAILED_TO_GET_RESULT_GAME_STATE
          ) {
            // Game state was not in correct state to get results
            addBreadcrumb({
              category: type,
              message: ErrorCodes.FAILED_TO_GET_RESULT_GAME_STATE,
              level: 'warning',
            });
            return;
          }
          setGameState(response, 'quiz_ended');
        } catch (error) {}
      },
      [emit, isCurrentQuestion, type, setGameState],
    ),
  });

  // Presenter actions
  useSubscribe({
    event: 'presenter_marked_answer',
    callback: React.useCallback(
      (gameState) => {
        if (!isCurrentQuestion(gameState.gameStateData)) return;
        if (type !== 'quiz-open') return;
        setGameState(gameState, 'presenter_marked_answer');
      },
      [isCurrentQuestion, setGameState, type],
    ),
  });

  useSubscribe({
    event: 'presenter_disconnected',
    callback: React.useCallback(() => {
      localState.current = 'idle';
      setGameState(
        {
          gameState: GameStateEnums.ERROR,
          gameStateData: {},
          errorCode: ErrorCodes.NO_AVAILABLE_QUIZ,
        },
        'presenter_disconnected',
      );
    }, [setGameState]),
  });

  // Trigger when multiple players with the same identifier is detected
  useSubscribe({
    event: 'force_disconnected',
    callback: React.useCallback(() => {
      localState.current = 'idle';
      setGameState(
        {
          gameState: GameStateEnums.ERROR,
          gameStateData: {},
          errorCode: ClientErrorCodes.MULTIPLE_DEVICES,
        },
        'force_disconnected',
      );
      if (connection?.disconnect) {
        // We will not recover from this
        connection.disconnect();
      }
    }, [connection, setGameState]),
  });

  // Automatically join a quiz when a connection has been established
  React.useEffect(() => {
    if (!connected) localState.current = 'idle';
    if (connected) {
      const timer = setTimeout(() => {
        joinQuiz();
      }, JOIN_QUIZ_AUTOMATIC_THRESHOLD); // If we don't get `quiz_created` within a certain time, join "manually"
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [joinQuiz, connected]);

  return {
    ready: Boolean(connection?.socket),
    getPlayerColor,
    joinQuiz,
    updatePlayer,
    submitChoiceVote,
    submitOpenAnswer,
    player,
    timeOffset,
    gameState,
    connected,
  };
};
