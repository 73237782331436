/* eslint-disable menti-react/filename-convention--jsx */
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';

export function Content() {
  const { useQuestion } = useVotingContext();
  const { question_description, title_meta, image, question } = useQuestion();

  return (
    <TitleSectionWithImage
      description={question_description}
      title={question}
      meta={title_meta}
      image={image}
    />
  );
}
