import 'katex/dist/katex.min.css';
import type { BoxT } from '@mentimeter/ragnar-ui';
import type { ImageResource } from '@mentimeter/http-clients';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { QuestionImage } from '../QuestionImage';
import { TitleSectionContainer } from './TitleSectionContainer';
import { VotingMetaText } from './VotingMetaText';
import { VotingTitle } from './VotingTitle';
import { VotingDescription } from './VotingDescription';

export interface TitleSectionWithImageProps extends BoxT {
  meta?: string | undefined;
  title?: string | undefined;
  titleStyled?: Record<string, unknown> | undefined;
  description?: string | undefined;
  image?: ImageResource | undefined | null;
  children?: React.ReactNode;
}

export function TitleSectionWithImage({
  meta,
  title,
  titleStyled,
  description,
  image,
  children,
  ...props
}: TitleSectionWithImageProps) {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();
  return (
    <TitleSectionContainer {...props}>
      <QuestionImage translateFn={translate} src={image} />
      <VotingMetaText meta={meta} />
      <VotingTitle title={title} titleStyled={titleStyled} />
      <VotingDescription description={description} />
    </TitleSectionContainer>
  );
}
