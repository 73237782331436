import React, { type MouseEvent } from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { GroupWithShape } from '../types';
import { TabsHeader, type TabName } from './TabsHeader';
import type { Upvotes } from './useUpvotes';
import { GroupedResponsesList } from './GroupedResponsesList';

interface GroupedResponsesWrapperProps {
  onTabClick: (
    tabName: TabName,
  ) => (event: MouseEvent<HTMLButtonElement>) => void;
  currentTab: TabName;
  totalUpvotes: number;
  handleUpvote: (id: string, voteCount: number) => void;
  upvotes: Upvotes;
  hasMultipleUpvotes: boolean;
  groups: GroupWithShape[];
}

export const GroupedResponsesWrapper = ({
  onTabClick,
  currentTab,
  totalUpvotes,
  handleUpvote,
  upvotes,
  hasMultipleUpvotes,
  groups,
}: GroupedResponsesWrapperProps) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  const numberOfGroups = groups.length;

  const shouldShowMyVotesEmptyState =
    currentTab === 'my-votes' && totalUpvotes === 0;

  const groupsWithUpvotes = groups
    .map((group) => ({
      ...group,
      responses: group.responses.filter((response) =>
        Boolean(upvotes[response.id]),
      ),
    }))
    .filter((group) => group.responses.length > 0);

  const groupsToShow = currentTab === 'all' ? groups : groupsWithUpvotes;

  const numberOfGroupsWithUpvotes = groupsWithUpvotes.length;

  return (
    <>
      <TabsHeader
        onTabClick={onTabClick}
        labelAll={`${translate(
          'open_ended.all_groups_tab_title',
        )} (${numberOfGroups})`}
        labelMyVotes={`${translate('open_ended.user_voted')} (${numberOfGroupsWithUpvotes})`}
      />
      <GroupedResponsesList
        groups={groupsToShow}
        onUpvote={handleUpvote}
        upvotes={upvotes}
        hasMultipleUpvotes={hasMultipleUpvotes}
        translate={translate}
      />
      {shouldShowMyVotesEmptyState && (
        <Box alignItems="center" justifyContent="center" width="100%" mt={4}>
          <Text textAlign="center" py={3}>
            {translate('open_ended.your_ideas')}
          </Text>
        </Box>
      )}
    </>
  );
};
