/* eslint-disable menti-react/filename-convention--jsx */
import { useState } from 'react';
import type { QuestionField } from '@mentimeter/http-clients';
import { Box, Form } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { QuickFormField } from './QuickFormField';

const CHECKBOX_FIELD_TYPE = 'checkbox';
const DATE_FIELD_TYPE = 'date';

function getToday() {
  const isoToday = new Date().toISOString();
  return isoToday.substr(0, isoToday.indexOf('T'));
}

function getInitialState(fields: Array<QuestionField>) {
  const values = {};
  fields.forEach(({ id, type }) => {
    if (type === CHECKBOX_FIELD_TYPE) {
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[id] = [];
    } else if (type === DATE_FIELD_TYPE) {
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[id] = [getToday()];
    } else {
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      values[id] = [''];
    }
  });
  return values;
}

export function Content() {
  const { useQuestion } = useVotingContext();
  const {
    question_description,
    title_meta,
    image,
    question: title,
    question_styled,
  } = useQuestion();

  return (
    <TitleSectionWithImage
      description={question_description}
      title={title}
      titleStyled={question_styled}
      meta={title_meta}
      image={image}
    />
  );
}

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { vote: submitVote } = useActions();
  const translate = useTranslate();

  const question = useQuestion();

  const { fields } = question;

  const [values, setValues] = useState(getInitialState(fields));
  const [showModal, setShowModal] = useState(false);

  const handleChange = (
    id: number,
    value: boolean | string,
    option: string | null | undefined,
  ) => {
    setValues(() => {
      // Handle text, email, select and date
      if (option === undefined) {
        return { ...values, [id]: [value] };
      }

      // Handle checkbox
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      let selectedOptions = values[id];
      if (value === true) {
        selectedOptions = [...selectedOptions, option];
      } else {
        // @ts-expect-error-auto TS(7006) FIXME: Parameter 'so' implicitly has an 'any' type.
        selectedOptions = [...selectedOptions.filter((so) => so !== option)];
      }
      return {
        ...values,
        [id]: selectedOptions,
      };
    });
  };

  const validate = () => {
    return fields.every((field) => {
      if (field.is_required) {
        // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const value = values[field.id];
        if (Array.isArray(value)) {
          if (field.type === 'dropdown') {
            return value[0] !== '';
          }
          return value.length > 0;
        }
        return Boolean(value);
      }
      return true;
    });
  };

  const handleSubmit = () => {
    const { fields } = question;
    if (!validate()) {
      setShowModal(true);
      return;
    }

    const vote = fields.map((field) => {
      const { id: fieldId } = field;
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return { field_id: fieldId, value: values[fieldId] };
    });
    submitVote(vote, false);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      width="100%"
    >
      <Box gap="space6" width="100%">
        {fields.map((f) => (
          <QuickFormField
            key={f.id}
            id={f.id}
            type={f.type}
            label={f.label}
            options={f.options}
            required={f.is_required}
            // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            value={values[f.id]}
            onChange={handleChange}
          />
        ))}
      </Box>
      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="skip-vote-modal-metadata"
        showModal={showModal}
        onConfirm={() => setShowModal(false)}
        onDismiss={() => setShowModal(false)}
        title={translate('buttons.retry')}
        bodyText={translate('messages.required_fields')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
