import * as React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { ClockIcon } from '@mentimeter/ragnar-visuals';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { Player } from '@mentimeter/quiz';
import { AvatarWrapper } from '../ui/Avatar';
import { FadeIn } from '../ui/Transitions';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const WaitForResults = ({
  player,
  playerColor,
  elapsedTime,
}: {
  player: Player | undefined;
  playerColor: string;
  elapsedTime: number;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  return (
    <>
      {player && <AvatarWrapper player={player} playerColor={playerColor} />}
      <FadeIn>
        <Box alignItems="center" mt="space14">
          <AnnouncedHeading
            textAlign="center"
            fontSize="175"
            color="text"
            fontWeight="regular"
          >
            {translate('state.how_you_did')}
          </AnnouncedHeading>

          <Box flexDirection="row" alignItems="flex-start" mt="space3">
            <Box mt="space1">
              <ClockIcon color="text" size={1} />
            </Box>
            <Text
              ml="space1"
              textAlign="center"
              color="text"
              // Due to <br> in translation
              dangerouslySetInnerHTML={{
                __html: `${Math.round(elapsedTime * 100) / 100} ${translate(
                  'state.seconds_to_answer',
                )}`,
              }}
            />
          </Box>
        </Box>
      </FadeIn>
    </>
  );
};
