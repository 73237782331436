import * as React from 'react';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { Box } from '@mentimeter/ragnar-ui';
import type { Player } from '@mentimeter/quiz';
import { CheckIcon, ClockIcon, CrossIcon } from '@mentimeter/ragnar-visuals';
import { AvatarStatusBubble, AvatarWrapper } from '../ui/Avatar';
import { FadeIn } from '../ui/Transitions';
import { AnnouncedHeading } from '../ui/AnnouncedHeading';

export const Results = ({
  player,
  playerColor,
  result,
}: {
  player: Player | undefined;
  playerColor: string;
  result: boolean | null;
}) => {
  const { useTranslate } = useVotingContext();
  const translate = useTranslate();

  let translationKey: string;
  let Icon = ClockIcon;
  switch (result) {
    case null: {
      translationKey = 'state.not_vote_in_time';
      break;
    }
    case true: {
      Icon = CheckIcon;
      translationKey = 'state.correct_answer';
      break;
    }
    case false: {
      Icon = CrossIcon;
      translationKey = 'state.wrong_answer';
    }
  }

  return (
    <>
      {player && <AvatarWrapper player={player} playerColor={playerColor} />}
      <FadeIn>
        <Box alignItems="center">
          <AvatarStatusBubble bg={result ? 'secondary' : 'neutralWeak'}>
            <Icon color={result ? 'onSecondary' : 'onNeutralWeak'} size={4} />
          </AvatarStatusBubble>
          <AnnouncedHeading
            textAlign="center"
            fontSize="175"
            mt="space3"
            color="text"
            fontWeight="regular"
          >
            {translate(translationKey)}
          </AnnouncedHeading>
        </Box>
      </FadeIn>
    </>
  );
};
