import { Text, Box, Range, type RangeT } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import type { Axis } from './types';

interface RowProps {
  index: number;
  id: number;
  value: number;
  allowNegative: boolean;
  labelMin: string;
  labelMax: string;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  onChange: (id: number, value: string, axis: Axis) => void;
  skipped: boolean;
  axis: Axis;
}

export function Row({
  index,
  id,
  label,
  allowNegative,
  labelMin,
  labelMax,
  min = 0,
  max = 5,
  step = 1,
  onChange,
  value,
  axis,
  skipped,
}: RowProps) {
  const { useTheme, useTranslate } = useVotingContext();
  const theme = useTheme();
  const fillColor: RangeT['thumbColor'] =
    theme.fillColors[index % theme.fillColors.length] || '';
  const translate = useTranslate();

  return (
    <>
      {/* Label */}

      <Box flexDirection="row" mt="space3">
        <Text
          id={`option-${id}-${index}`}
          color="text"
          mr="space1.5"
          lineHeight="150"
        >
          {allowNegative && label}
        </Text>
        <Text fontWeight="semiBold" color="text" aria-hidden>
          {allowNegative ? value : Math.abs(value)}
        </Text>
      </Box>

      {/* Label */}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pt="space2"
      >
        <Text
          data-testid={`voting-range-min-value-${index}`}
          color="text"
          mr={2}
          aria-hidden
        >
          {allowNegative ? min : Math.abs(min)}
        </Text>

        <Range
          name={`2-by-2-input-${index}`}
          min={min}
          max={max}
          value={value}
          /**
           * [A11Y] Range properties with sliders
           * @see https://www.w3.org/WAI/ARIA/apg/practices/range-related-properties/#rangepropertieswithsliders
           */
          aria-valuetext={translate('rating.aria_valuetext', {
            value,
            labelMin,
            labelMax,
          })}
          aria-label={label}
          step={step}
          disabled={skipped}
          onChange={(e) => onChange(id, e.target.value, axis)}
          thumbColor={fillColor}
        />
        <Text
          data-testid={`voting-range-max-value-${index}`}
          color="text"
          ml={2}
          mb="space3"
          aria-hidden
        >
          {max}
        </Text>
      </Box>

      {/* Labels */}
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        aria-hidden={true}
      >
        <Text fontSize={1}>{labelMin}</Text>
        <Text fontSize={1} textAlign="right">
          {labelMax}
        </Text>
      </Box>
    </>
  );
}
