import {
  Button,
  Light,
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
  Text,
} from '@mentimeter/ragnar-ui';
import React from 'react';

export interface VotingConfirmationModalProps {
  id: string;
  showModal: boolean;
  title: string;
  confirmButtonText?: string;
  dismissButtonText?: string;
  bodyText?: string;
  onConfirm: () => void;
  onDismiss: () => void;
  // if not set, onDismiss will be used
  onOpenChange?: (open: boolean) => void;
}

export function VotingConfirmationModal({
  id,
  showModal,
  confirmButtonText,
  dismissButtonText,
  title,
  bodyText,
  onConfirm,
  onDismiss,
  onOpenChange,
}: VotingConfirmationModalProps) {
  return (
    <Light>
      <ModalRoot open={showModal} onOpenChange={onOpenChange ?? onDismiss}>
        <ModalContainer size="small">
          <ModalHeader data-testid={id}>{title}</ModalHeader>
          <ModalBody>
            {bodyText && (
              <Text fontSize="100" color="text">
                {bodyText}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <ModalDismiss>
              {dismissButtonText && (
                <Button size="large" onClick={onDismiss}>
                  {dismissButtonText}
                </Button>
              )}
            </ModalDismiss>
            <ModalDismiss>
              {confirmButtonText && (
                <Button
                  size="large"
                  data-testid={`${id}-confirm`}
                  variant="primary"
                  onClick={onConfirm}
                >
                  {confirmButtonText}
                </Button>
              )}
            </ModalDismiss>
          </ModalFooter>
        </ModalContainer>
      </ModalRoot>
    </Light>
  );
}
