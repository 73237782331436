import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mentimeter/ragnar-ui';
import { useSortable } from '@dnd-kit/sortable';
import type { RankableChoice } from './types';
import { DropItem } from './DropItem';
interface UnrankedDropItemT {
  choice: RankableChoice;
  sendToRanked?: (choice: RankableChoice) => void;
  ariaLabel?: string;
}

export const UnrankedDropItem = ({
  choice,
  sendToRanked,
  ariaLabel,
}: UnrankedDropItemT) => {
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id: choice.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    opacity: isDragging ? 0.2 : 1,
  };

  return (
    <Box
      mb="space4"
      borderRadius="4xl"
      width="100%"
      ref={setNodeRef}
      style={style}
      {...listeners}
      extend={({ theme }) => ({
        boxShadow: `0px 0px 0px 1px ${theme.colors.border}`,

        '&:has(:focus)': {
          boxShadow: `0px 0px 0px 2px ${theme.colors.secondary}`,
          outline: `4px solid ${theme.colors.interactiveFocused}`,
          outlineOffset: '4px',
        },
      })}
    >
      {sendToRanked ? (
        <DropItem
          choice={choice}
          ariaLabel={ariaLabel}
          sendToRanked={sendToRanked}
        />
      ) : null}
    </Box>
  );
};
