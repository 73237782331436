/* eslint-disable menti-react/filename-convention--jsx */
import { useState } from 'react';
import type { QuestionChoice, QuestionSubType } from '@mentimeter/http-clients';
import { Box, Form, ScreenReaderOnly } from '@mentimeter/ragnar-ui';
import { useVotingContext } from '@mentimeter/question-modules-contexts';
import { VotingConfirmationModal } from '@mentimeter/voting-ui';
import { SubmitVoteFormButton } from '../../ui/Components/SubmitVoteFormButton';
import { TitleSectionWithImage } from '../../ui/Components/VotingTitle';
import { ALLOW_SELECT_ALL_CHOICES, Option } from './MultipleChoice';

export function Content() {
  const { useQuestion } = useVotingContext();
  const question = useQuestion();

  return (
    <TitleSectionWithImage
      image={question.image}
      meta={question.title_meta}
      title={question.question}
      titleStyled={question.question_styled}
      description={question.question_description}
    />
  );
}

const removeImageFromChoices = (choices: QuestionChoice[]) => {
  return choices.map((choice) => {
    const {
      image,
      image_alt_description,
      image_path,
      image_url,
      media_params,
      cropped_image_url,
      ...rest
    } = choice;
    return rest;
  });
};

const removeImageIfNotBarChart = (
  subType: QuestionSubType,
  choices: QuestionChoice[],
) => {
  // treating null/undefined as 'choices' as it is the default and we happen to have slides with no sub_type
  if (!subType || subType === 'choices') {
    return choices;
  } else {
    return removeImageFromChoices(choices);
  }
};

export function Interactive() {
  const { useQuestion, useActions, useTranslate } = useVotingContext();
  const { skip: skipVote, vote: submitVote } = useActions();
  const question = useQuestion();
  const translate = useTranslate();
  const [showModal, setShowModal] = useState(false);

  const [rawVotes, setVotes] = useState<string[]>([]);
  const votes =
    question.max_votes !== ALLOW_SELECT_ALL_CHOICES
      ? rawVotes.slice(0, question.max_votes)
      : rawVotes;

  const filteredChoices = removeImageIfNotBarChart(
    question.sub_type,
    question.choices,
  );

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (!showModal && votes.length === 0) {
          setShowModal(true);
        } else {
          const allowMultiple =
            question.max_votes === -1 || question.max_votes > 1;
          if (allowMultiple) {
            submitVote(votes, false);
          } else {
            submitVote(votes[0], false);
          }
        }
      }}
      width="100%"
    >
      <Box as="fieldset" width="100%">
        <ScreenReaderOnly as="legend">{question.question}</ScreenReaderOnly>
        <Option
          maxVotes={question.max_votes}
          choices={filteredChoices}
          translate={translate}
          votes={votes}
          setVotes={setVotes}
        />
      </Box>

      <SubmitVoteFormButton />
      <VotingConfirmationModal
        id="skip-vote-modal-choices"
        showModal={showModal}
        onConfirm={skipVote}
        onDismiss={() => setShowModal(false)}
        title={translate('messages.not_made_choice')}
        bodyText={translate('messages.continue_without_voting')}
        confirmButtonText={translate('buttons.ok')}
        dismissButtonText={translate('buttons.cancel')}
      />
    </Form>
  );
}
