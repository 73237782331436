import React from 'react';
import { COLOR_MAP } from '../colorMap';
import type { Pictogram } from '../types';
import { Svg } from '../../svg';

export const ArrowRight = ({ userColor, variant = 'purple' }: Pictogram) => {
  return (
    <Svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      aria-hidden="true"
      className="light"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.246 156.491L157.491 99.2456L100.246 42L80.0711 62.1746L117.142 99.246L80.0714 136.317L100.246 156.491Z"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
      <rect
        x="49"
        y="85"
        width="84"
        height="30"
        fill={userColor ? userColor : COLOR_MAP[variant].pictogramPrimary}
      />
    </Svg>
  );
};
