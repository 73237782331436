import { animated, useSpring, config } from '@react-spring/web';

export const ScaleIn = ({ children }: React.PropsWithChildren) => {
  const [springs] = useSpring(() => ({
    config: config.wobbly,
    from: { transform: `scale(0)` },
    to: [{ transform: `scale(1)` }],
  }));
  return <animated.div style={springs}>{children}</animated.div>;
};

export const FadeIn = ({ children }: React.PropsWithChildren) => {
  const [springs] = useSpring(() => ({
    config: config.stiff,
    from: { opacity: 0 },
    to: [{ opacity: 1 }],
  }));
  return <animated.div style={springs}>{children}</animated.div>;
};
